import {
  AccountTree,
  AlternateEmail,
  Business,
  Colorize,
  Delete,
  Event,
  Gavel,
  LanguageOutlined,
  Numbers,
  Payments,
  Phone,
  Photo,
  Receipt,
  Scale,
  Sell,
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Switch,
  Tab,
  TextField,
  useTheme,
} from "@mui/material";
import { isEqual, mergeWith } from "lodash";
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import {
  GetOrganizationSettingsQuery,
  InvoicingProcess,
  Language,
  MeasurementSystem,
  TransactionType,
} from "../../../graphql/generated";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import DocumentsUploadModal from "../../common/DocumentsUploadModal";
import EnumSelect from "../../common/EnumSelect";
import TransactionTemplatesModal from "../../accounting/TransationTemplatesModal/TransactionTemplatesModal";
import { isQa } from "../../../config/endpoint";
import DemoUtils from "../DemoUtils/DemoUtils";
import languageLabel from "../../../utils/labels/languageLabel";
import { useTranslation } from "react-i18next";
import CustomFieldDefinitionsListContainer from "../../custom-field-definitions/CustomFieldsList";

type OrganizationSettingsEditorData =
  GetOrganizationSettingsQuery["organizationSettings"];

type OrgSettingsEditorProps = {
  organizationSettings: OrganizationSettingsEditorData | null;
  onSave: (data: OrganizationSettingsEditorData) => void;
};

type DeepPartialOrganizationSettingsEditorData = {
  [key in keyof Exclude<
    OrganizationSettingsEditorData,
    null | undefined
  >]: Partial<Exclude<OrganizationSettingsEditorData, null | undefined>[key]>;
};
const OrgSettingsEditor = ({
  organizationSettings,
  onSave,
}: OrgSettingsEditorProps) => {
  const { t } = useTranslation("settings");
  const [activeTab, setActiveTab] = useState("GENERAL");
  const [localOrganizationSettings, setLocalOrganizationSettings] =
    useState<OrganizationSettingsEditorData | null>(organizationSettings);
  const [logoModalOpen, setLogoModalOpen] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [chargeTemplatesModalOpen, setChargeTemplatesModalOpen] =
    useState(false);
  const [expenseTemplatesModalOpen, setExpenseTemplatesModalOpen] =
    useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowPicker(true);
  };

  const theme = useTheme();

  const previousOrganizationSettings = usePrevious(organizationSettings);
  useEffect(() => {
    if (!isEqual(previousOrganizationSettings, organizationSettings)) {
      setLocalOrganizationSettings(organizationSettings);
    }
  }, [organizationSettings, previousOrganizationSettings]);

  const updateOrganizationSettings = (
    updates: DeepPartialOrganizationSettingsEditorData,
    autoSave = false
  ) => {
    const updatedSettings = mergeWith(
      {},
      localOrganizationSettings,
      updates,
      (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return srcValue;
        }
      }
    );
    setLocalOrganizationSettings(updatedSettings);
    if (autoSave) {
      onSave(updatedSettings);
    }
  };
  return (
    <Box>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(event, value) => setActiveTab(value)}
            aria-label="Organization settings tabs"
          >
            <Tab
              label={t("general.label", "General")}
              value="GENERAL"
              aria-label="General"
            />
            <Tab
              label={t("invoicing.label", "Invoicing")}
              value="INVOICING"
              aria-label="Invoicing"
            />
            <Tab
              label={t("ratecon.label", "Ratecon")}
              value="RATECON"
              aria-label="Ratecon"
            />
            <Tab
              label={"Custom Fields"}
              value="CUSTOM_FIELDS"
              aria-label="Custom Fields"
            />
            {isQa ? (
              <Tab
                label={t("demoUtils.label", "Demo Utils")}
                value="DEMO_UTILS"
                aria-label="Demo Utils"
              />
            ) : null}
          </TabList>
        </Box>
        <TabPanel value="GENERAL">
          <ListItem
            divider
            secondaryAction={
              <EnumSelect
                label={t("general.language", "Language")}
                sx={{
                  width: 200,
                }}
                enumObject={Language}
                optionLabel={languageLabel}
                name="language"
                id="language-select"
                value={localOrganizationSettings?.general?.language || null}
                onChange={(event, value) => {
                  updateOrganizationSettings({
                    general: {
                      language: value,
                    },
                  });
                }}
              />
            }
          >
            <ListItemIcon>
              <LanguageOutlined />
            </ListItemIcon>
            <ListItemText
              primary={t("general.language", "Language")}
              secondary={t(
                "general.languageDesc",
                "Language used for the user interface. (if not set, the browser language will be used)"
              )}
            />
          </ListItem>
          <ListItem
            divider
            secondaryAction={
              <EnumSelect
                label={t("general.measurementSystem", "Measurement System")}
                sx={{
                  width: 200,
                }}
                enumObject={MeasurementSystem}
                name="measurementSystem"
                id="measurement-system-select"
                value={
                  localOrganizationSettings?.general?.measurementSystem || null
                }
                onChange={(event, value) => {
                  updateOrganizationSettings({
                    general: {
                      measurementSystem: value,
                    },
                  });
                }}
              />
            }
          >
            <ListItemIcon>
              <Scale />
            </ListItemIcon>
            <ListItemText
              primary={t("general.measurementSystem", "Measurement System")}
              secondary={t(
                "general.measurementSystemDesc",
                "Determines the units used for displaying weights, distances, volumes, etc."
              )}
            />
          </ListItem>

          <ListItem
            divider
            secondaryAction={
              <Switch
                checked={
                  !!localOrganizationSettings?.general?.useSimpleWorkflow
                }
                onChange={(_, checked) => {
                  updateOrganizationSettings({
                    general: {
                      useSimpleWorkflow: checked,
                    },
                  });
                }}
              />
            }
          >
            <ListItemIcon>
              <AccountTree />
            </ListItemIcon>
            <ListItemText
              primary={t("general.useSimpleFlow", "Use Simple Workflow")}
              secondary={t(
                "general.useSimpleFlowDesc",
                "When enabled, drivers only have to mark a location as Done, they don't have to log arrival, load and departure events"
              )}
            />
          </ListItem>

          <ListItem
            divider
            secondaryAction={
              <Switch
                checked={
                  !!localOrganizationSettings?.general?.driversCanSeeFutureTrips
                }
                onChange={(_, checked) => {
                  updateOrganizationSettings({
                    general: {
                      driversCanSeeFutureTrips: checked,
                    },
                  });
                }}
              />
            }
          >
            <ListItemIcon>
              <Event />
            </ListItemIcon>
            <ListItemText
              primary={t(
                "general.canSeeFutureTrips",
                "Drivers can see future trips"
              )}
              secondary={t(
                "general.canSeeFutureTripsDesc",
                "When enabled, drivers can future trips they are assigned to in their planning"
              )}
            />
          </ListItem>
        </TabPanel>
        <TabPanel value="INVOICING">
          <List>
            <ListItem
              divider
              secondaryAction={
                <Box>
                  <Button
                    variant="outlined"
                    id="change-logo-button"
                    onClick={() => {
                      setLogoModalOpen(true);
                    }}
                  >
                    {!localOrganizationSettings?.invoicing?.logoUrl
                      ? t("select", "Select")
                      : t("change", "Change")}
                  </Button>
                  {localOrganizationSettings?.invoicing?.logoUrl ? (
                    <IconButton
                      sx={{ ml: 1 }}
                      color="error"
                      id="remove-logo-button"
                      onClick={() => {
                        updateOrganizationSettings(
                          {
                            invoicing: {
                              logoUrl: null,
                            },
                          },
                          false
                        );
                      }}
                    >
                      <Delete />
                    </IconButton>
                  ) : null}
                </Box>
              }
            >
              {localOrganizationSettings?.invoicing?.logoUrl ? (
                <ListItemAvatar>
                  <Avatar
                    alt={"Invoice logo"}
                    src={localOrganizationSettings?.invoicing?.logoUrl}
                  />
                </ListItemAvatar>
              ) : (
                <ListItemIcon>
                  <Photo />
                </ListItemIcon>
              )}
              <ListItemText
                primary={t("invoicing.logo", "Logo")}
                secondary={t(
                  "invoicing.logoDesc",
                  "Logo displayed at the top of your invoice. Should preferrably be 100x100px."
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <Box
                  sx={{
                    border: `3px solid ${theme.palette.primary.main}`,
                    padding: 0.3,
                    borderRadius: 2,
                  }}
                  display="flex"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      width: 40,
                      height: 40,
                      borderRadius: 1,
                      backgroundColor:
                        localOrganizationSettings?.invoicing?.primaryColor ||
                        theme.palette.primary.main,
                    }}
                    onClick={handleClick}
                  />
                  <Popover
                    open={showPicker}
                    anchorEl={anchorEl}
                    onClose={() => setShowPicker(false)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <SketchPicker
                      disableAlpha
                      color={
                        localOrganizationSettings?.invoicing?.primaryColor ||
                        theme.palette.primary.main
                      }
                      onChange={(newColor) => {
                        updateOrganizationSettings({
                          invoicing: {
                            primaryColor: newColor.hex,
                          },
                        });
                      }}
                    />
                  </Popover>
                </Box>
              }
            >
              <ListItemIcon>
                <Colorize />
              </ListItemIcon>
              <ListItemText
                primary={t("invoicing.invoiceColor", "Invoice Color")}
                secondary={t(
                  "invoicing.invoiceColorDesc",
                  "Color used in the invoice tables. Click the box on the right to change it."
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <TextField
                  label="Company Address"
                  size="small"
                  name="companyAddress"
                  value={
                    localOrganizationSettings?.invoicing?.companyAddress || ""
                  }
                  onChange={(event) => {
                    updateOrganizationSettings({
                      invoicing: {
                        companyAddress: event.target.value,
                      },
                    });
                  }}
                />
              }
            >
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText
                primary={t("invoicing.companyAddress", "Company Address")}
                secondary={t(
                  "invoicing.companyAddressDesc",
                  "This will be added to the header of the Invoice."
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <TextField
                  label={t("invoicing.companyPhone", "Company Phone")}
                  size="small"
                  name="companyPhone"
                  value={
                    localOrganizationSettings?.invoicing?.companyPhone || ""
                  }
                  onChange={(event) => {
                    updateOrganizationSettings({
                      invoicing: {
                        companyPhone: event.target.value,
                      },
                    });
                  }}
                />
              }
            >
              <ListItemIcon>
                <Phone />
              </ListItemIcon>
              <ListItemText
                primary={t("invoicing.companyPhone", "Company Phone")}
                secondary={t(
                  "invoicing.companyPhoneDesc",
                  "This will be added to the header of the Invoice."
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <TextField
                  label={t("invoicing.companyEmail", "Company Email")}
                  size="small"
                  name="companyEmail"
                  value={
                    localOrganizationSettings?.invoicing?.companyEmail || ""
                  }
                  onChange={(event) => {
                    updateOrganizationSettings({
                      invoicing: {
                        companyEmail: event.target.value,
                      },
                    });
                  }}
                />
              }
            >
              <ListItemIcon>
                <AlternateEmail />
              </ListItemIcon>
              <ListItemText
                primary={t("invoicing.companyEmail", "Company Email")}
                secondary={t(
                  "invoicing.companyEmailDesc",
                  "This will be added to the header of the Invoice."
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <TextField
                  label={t("invoicing.prefix", "Prefix")}
                  size="small"
                  name="invoicePrefix"
                  value={localOrganizationSettings?.invoicing?.prefix || ""}
                  onChange={(event) => {
                    updateOrganizationSettings({
                      invoicing: {
                        prefix: event.target.value,
                      },
                    });
                  }}
                />
              }
            >
              <ListItemIcon>
                <Numbers />
              </ListItemIcon>
              <ListItemText
                primary={t(
                  "invoicing.invoiceNumberPrefix",
                  "Invoice Number Prefix"
                )}
                secondary={t(
                  "invoicing.invoiceNumberPrefixDesc",
                  "This will be added to the beginning of your invoice numbers."
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <Button
                  variant="outlined"
                  onClick={() => {
                    setChargeTemplatesModalOpen(true);
                  }}
                >
                  {t("configure", "Configure")}
                </Button>
              }
            >
              <ListItemIcon>
                <Sell />
              </ListItemIcon>
              <ListItemText
                primary={t("invoicing.chargeTypes", "Charge Types")}
                secondary={t(
                  "invoicing.chargeTypesDesc",
                  "The line haul charge types that are available to use when invoicing a customer"
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <Button
                  variant="outlined"
                  onClick={() => {
                    setExpenseTemplatesModalOpen(true);
                  }}
                >
                  {t("configure", "Configure")}
                </Button>
              }
            >
              <ListItemIcon>
                <Payments />
              </ListItemIcon>
              <ListItemText
                primary={t("invoicing.accessorialTypes", "Accessorial Types")}
                secondary={t(
                  "invoicing.accessorialTypesDesc",
                  "The accessorial types that are available to use when invoicing a customer or adding an expense"
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <EnumSelect
                  label={t("invoicing.model", "Invoicing Model")}
                  sx={{
                    width: 200,
                  }}
                  enumObject={InvoicingProcess}
                  name="invoicingProcess"
                  id="invoicing-process-select"
                  multiple
                  value={
                    localOrganizationSettings?.invoicing?.processes || null
                  }
                  onChange={(event, value) => {
                    updateOrganizationSettings({
                      invoicing: {
                        processes: value,
                      },
                    });
                  }}
                />
              }
            >
              <ListItemIcon>
                <Scale />
              </ListItemIcon>
              <ListItemText
                primary={t("invoicing.model", "Invoicing Model")}
                secondary={t(
                  "invoicing.modelDesc",
                  "Determines whether your loads will be invoiced from the load details or from the billing queue."
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <Switch
                  checked={
                    !!localOrganizationSettings?.invoicing?.enableTaxRate
                  }
                  onChange={(_, checked) => {
                    updateOrganizationSettings({
                      invoicing: {
                        enableTaxRate: checked,
                      },
                    });
                  }}
                />
              }
            >
              <ListItemIcon>
                <Receipt />
              </ListItemIcon>
              <ListItemText
                primary={t("invoicing.enableTaxRate")}
                secondary={t("invoicing.enableTaxRateDesc")}
              />
            </ListItem>
          </List>
        </TabPanel>

        <TabPanel value="RATECON">
          <List>
            <ListItem>
              <ListItemIcon>
                <Gavel />
              </ListItemIcon>
              <ListItemText
                primary={t("ratecon.tac", "Ratecon Terms and Conditions")}
                secondary={t(
                  "ratecon.tacDesc",
                  "This will be added to 2nd page of the RATECONs you generate."
                )}
              />
            </ListItem>

            <Box>
              <TextField
                label={t("ratecon.conditions", "Conditions")}
                size="small"
                multiline
                minRows={5}
                maxRows={7}
                fullWidth
                name="rateconTermsAndConditions"
                value={
                  localOrganizationSettings?.ratecon?.termsAndConditions || ""
                }
                onChange={(event) => {
                  updateOrganizationSettings({
                    ratecon: {
                      termsAndConditions: event.target.value,
                    },
                  });
                }}
              />
            </Box>

            <ListItem
              divider
              secondaryAction={
                <TextField
                  label={t("ratecon.companyAddress", "Company Address")}
                  size="small"
                  name="companyAddress"
                  value={
                    localOrganizationSettings?.ratecon?.companyAddress || ""
                  }
                  onChange={(event) => {
                    updateOrganizationSettings({
                      ratecon: {
                        companyAddress: event.target.value,
                      },
                    });
                  }}
                />
              }
            >
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText
                primary={t("ratecon.companyAddress", "Company Address")}
                secondary={t(
                  "ratecon.companyAddressDesc",
                  "This will be added to the header of the RATECON."
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <TextField
                  label={t("ratecon.companyPhone", "Company Phone")}
                  size="small"
                  name="companyPhone"
                  value={localOrganizationSettings?.ratecon?.companyPhone || ""}
                  onChange={(event) => {
                    updateOrganizationSettings({
                      ratecon: {
                        companyPhone: event.target.value,
                      },
                    });
                  }}
                />
              }
            >
              <ListItemIcon>
                <Phone />
              </ListItemIcon>
              <ListItemText
                primary={t("ratecon.companyPhone", "Company Phone")}
                secondary={t(
                  "ratecon.companyPhoneDesc",
                  "This will be added to the header of the RATECON."
                )}
              />
            </ListItem>

            <ListItem
              divider
              secondaryAction={
                <TextField
                  label={t("ratecon.companyEmail", "Company Email")}
                  size="small"
                  name="companyEmail"
                  value={localOrganizationSettings?.ratecon?.companyEmail || ""}
                  onChange={(event) => {
                    updateOrganizationSettings({
                      ratecon: {
                        companyEmail: event.target.value,
                      },
                    });
                  }}
                />
              }
            >
              <ListItemIcon>
                <AlternateEmail />
              </ListItemIcon>
              <ListItemText
                primary={t("ratecon.companyEmail", "Company Email")}
                secondary={t(
                  "ratecon.companyEmailDesc",
                  "This will be added to the header of the RATECON."
                )}
              />
            </ListItem>
          </List>
        </TabPanel>

        <TabPanel value="DEMO_UTILS">
          <DemoUtils />
        </TabPanel>

        <TabPanel value="CUSTOM_FIELDS">
          <CustomFieldDefinitionsListContainer />
        </TabPanel>
      </TabContext>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          pr: 3,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isEqual(organizationSettings, localOrganizationSettings)}
          onClick={() => {
            if (!localOrganizationSettings) {
              return null;
            }
            onSave(localOrganizationSettings);
          }}
          id="save-settings-button"
        >
          {t("save", "Save")}
        </Button>
      </Stack>
      <DocumentsUploadModal
        isOpen={logoModalOpen}
        multiple={false}
        hasLabel={false}
        title={t("general.uploadYourLogo", "Upload your logo")}
        accept="image/*"
        onCancel={() => {
          setLogoModalOpen(false);
        }}
        onSubmit={(documents) => {
          updateOrganizationSettings(
            {
              invoicing: {
                logoUrl: documents[0].url,
              },
            },
            true
          );
          setLogoModalOpen(false);
        }}
      />
      <TransactionTemplatesModal
        open={chargeTemplatesModalOpen}
        templates={
          organizationSettings?.transactionTemplates?.filter(
            (t) => t.type === TransactionType.Income
          ) || []
        }
        templateType={TransactionType.Income}
        onClose={() => {
          setChargeTemplatesModalOpen(false);
        }}
        onSubmit={(data) => {
          updateOrganizationSettings(
            {
              transactionTemplates: [
                ...data,
                ...(organizationSettings?.transactionTemplates?.filter(
                  (t) => t.type !== TransactionType.Income
                ) || []),
              ],
            },
            true
          );
          setChargeTemplatesModalOpen(false);
        }}
      />
      <TransactionTemplatesModal
        open={expenseTemplatesModalOpen}
        templates={
          organizationSettings?.transactionTemplates?.filter(
            (t) => t.type === TransactionType.Expense
          ) || []
        }
        templateType={TransactionType.Expense}
        onClose={() => {
          setExpenseTemplatesModalOpen(false);
        }}
        onSubmit={(data) => {
          updateOrganizationSettings(
            {
              transactionTemplates: [
                ...data,
                ...(organizationSettings?.transactionTemplates?.filter(
                  (t) => t.type !== TransactionType.Expense
                ) || []),
              ],
            },
            true
          );
          setExpenseTemplatesModalOpen(false);
        }}
      />
    </Box>
  );
};

export default OrgSettingsEditor;
