import { MenuItem, Stack, TextField } from "@mui/material";
import {
  DriverHosStatus,
  GetBusinessEntityListQuery,
  TrailerType,
} from "../../../graphql/generated";
import EnumSelect from "../../common/EnumSelect";
import StateSelect from "../../common/StateSelect";
import { useTranslation } from "react-i18next";

type AssetFiltersProps = {
  filters: AssetFiltersValue;
  domiciles: GetBusinessEntityListQuery["businessEntities"]["data"];
  onChange: (filters: AssetFiltersValue) => void;
};

export type AssetFiltersValue = Partial<{
  trailerTypes: TrailerType[] | null;
  originStates: string[] | null;
  destinationStates: string[] | null;
  originCities: string[] | null;
  destinationCities: string[] | null;
  hosRange: number[] | null;
  hosStatus: DriverHosStatus | null;
  domicileIds: string[] | null;
}>;
const AssetFilters = ({ filters, domiciles, onChange }: AssetFiltersProps) => {
  const { t } = useTranslation("planning");

  return (
    <Stack spacing={2} paddingTop={2}>
      <EnumSelect
        multiple
        enumObject={TrailerType}
        label={t("filters.trailerType")}
        value={filters.trailerTypes}
        onChange={(_, trailerTypes) => onChange({ ...filters, trailerTypes })}
      />
      <StateSelect
        SelectProps={{ multiple: true }}
        label={t("filters.originState")}
        value={filters.originStates || []}
        onChange={(event) =>
          onChange({
            ...filters,
            originStates: event.target.value as unknown as string[],
          })
        }
      />
      <StateSelect
        SelectProps={{ multiple: true }}
        label={t("filters.destinationState")}
        value={filters.destinationStates || []}
        onChange={(event) =>
          onChange({
            ...filters,
            destinationStates: event.target.value as unknown as string[],
          })
        }
      />
      <TextField
        label={t("filters.originCity")}
        value={filters.originCities?.join(", ") || ""}
        onChange={(event) =>
          onChange({
            ...filters,
            originCities: event.target.value
              .split(",")
              .map((city) => city.trim())
              .filter(Boolean),
          })
        }
      />
      <TextField
        label={t("filters.destinationCity")}
        value={filters.destinationCities?.join(", ") || ""}
        onChange={(event) =>
          onChange({
            ...filters,
            destinationCities: event.target.value
              .split(",")
              .map((city) => city.trim())
              .filter(Boolean),
          })
        }
      />

      <TextField
        select
        label={t("filters.domicile")}
        value={filters?.domicileIds || []}
        onChange={(event) => {
          onChange({
            ...filters,
            // @ts-ignore
            domicileIds: event.target.value as string[],
          });
        }}
        SelectProps={{
          multiple: true,
        }}
        fullWidth
      >
        {domiciles.map((domicile) => (
          <MenuItem key={domicile._id} value={domicile._id}>
            {domicile.name}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};

export default AssetFilters;
