const endpointByDomain: { [key: string]: string | undefined } = {
  "app.truetms.com": "https://api.truetms.com",
  "beta.truetms.com": "https://beta-api.truetms.com",
  "qa-lynks-web.tecafrik.com": "https://qa-lynks-api.tecafrik.com",
};

export const isQa = window.location.host !== "app.truetms.com";
export const stage =
  window.location.host === "app.truetms.com"
    ? "prod"
    : window.location.host === "beta.truetms.com"
    ? "beta"
    : "qa";

export const endpoint =
  // "http://localhost:3001" ||
  endpointByDomain[window.location.host] || "https://qa-lynks-api.tecafrik.com";
