import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetTripQuery,
  useDeleteTripMutation,
  useGetTripPositionsQuery,
  useGetTripQuery,
} from "../../../graphql/generated";
import {
  hideDialog,
  showDialog,
} from "../../../redux/slices/alert/Alert.slice";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import LoadingOverlay from "../../common/LoadingOverlay";
import TripDetails from "./TripDetails";
import { useTranslation } from "react-i18next";

type TripDetailsContainerProps = {
  tripId: string;
  onLoad?: (trip: Exclude<GetTripQuery["tripById"], null | undefined>) => void;
};

function TripDetailsContainer({ onLoad, tripId }: TripDetailsContainerProps) {
  const { t } = useTranslation(["trips", "common"]);
  const getTripQuery = useGetTripQuery(
    { id: tripId },
    {
      refetchInterval: 5000,
    }
  );
  const getTripPositionsQUery = useGetTripPositionsQuery({
    tripId,
  });
  const deleteTripMutation = useDeleteTripMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getTripQuery.data?.tripById) {
      onLoad?.(getTripQuery.data?.tripById);
    }
  }, [onLoad, getTripQuery.data?.tripById]);

  if (getTripQuery.isLoading) {
    return (
      <Box
        sx={{
          height: "100%",
          position: "relative",
        }}
      >
        <LoadingOverlay loading={true} />
      </Box>
    );
  }

  if (!getTripQuery.data?.tripById) {
    return (
      <Box
        sx={{
          height: "100%",
          position: "relative",
        }}
      >
        <ErrorMessage message={t("tripNotFound", "Trip not found")} />
      </Box>
    );
  }
  return (
    <TripDetails
      trip={getTripQuery.data?.tripById}
      tripPositions={getTripPositionsQUery.data?.tripPositions || []}
      loading={getTripQuery.isLoading || deleteTripMutation.isLoading}
      error={(getTripQuery.error || deleteTripMutation.error) as Error}
      onDelete={async () => {
        dispatch(
          showDialog({
            title: t("common:error.dangerZone", "Danger zone"),
            description: t("deleteTripConfirmation", {
              tripNumber: getTripQuery.data?.tripById?.tripNumber,
              defaultValue: `Do you really want to delete the trip #${getTripQuery.data?.tripById?.tripNumber}? This action cannot be undone.`,
            }),
            type: "error",
            actions: [
              {
                type: "error",
                title: t("common:error.yesDelete", "Yes, Delete"),
                onClick: async () => {
                  if (!tripId) {
                    return;
                  }
                  await deleteTripMutation.mutateAsync({ id: tripId });
                  dispatch(hideDialog());
                  navigate("/trips");
                },
              },
              {
                type: "primary",
                title: t("common:error.noCancel", "No, Cancel"),
                onClick: () => dispatch(hideDialog()),
              },
            ],
          })
        );
      }}
      onRefresh={() => {
        getTripQuery.refetch();
      }}
    />
  );
}

export default TripDetailsContainer;
