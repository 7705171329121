import {
  useGetBusinessEntityDetailsQuery,
  useGetBusinessStorageFacilitiesQuery,
} from "../../../../graphql/generated";
import { useDispatch } from "react-redux";
import { ShipmentLocationInputData } from "../../../../redux/slices/Types";
import ReceiverForm, { ReceiverFormProps } from "./ReceiverForm";
import {
  editShipmentLocationInput,
  selectDefaultShipperForLocation,
} from "../../../../redux/slices/shipment/ShipmentForm.slice";

type ReceiverContainerProps = Omit<
  ReceiverFormProps,
  "onChange" | "storageFacilities" | "shipmentLocation"
> & {
  receiver: ShipmentLocationInputData;
  onChange?: (receiver: ShipmentLocationInputData) => void;
};

export default function ReceiverFormContainer({
  receiver: receiverLocation,
  onChange,
  ...props
}: ReceiverContainerProps) {
  const dispatch = useDispatch();

  const handleChange = async (
    updatedReceiverLocation: ShipmentLocationInputData
  ) => {
    if (onChange) {
      onChange(updatedReceiverLocation);
    } else {
      dispatch(
        editShipmentLocationInput({
          location: updatedReceiverLocation,
        })
      );

      if (updatedReceiverLocation.receiver !== receiverLocation.receiver) {
        const businessEntityDetailsResponse =
          await (updatedReceiverLocation.receiver
            ? useGetBusinessEntityDetailsQuery.fetcher({
                id: updatedReceiverLocation.receiver,
              })()
            : null);
        if (!businessEntityDetailsResponse?.businessEntityById) {
          return;
        }
        dispatch(
          selectDefaultShipperForLocation({
            locationId: updatedReceiverLocation._id,
            businessEntity: businessEntityDetailsResponse?.businessEntityById,
          })
        );
      }
    }
  };

  const receiverStorageFacilitiesQuery = useGetBusinessStorageFacilitiesQuery(
    {
      id: receiverLocation.receiver || "",
    },
    {
      enabled: !!receiverLocation.receiver,
    }
  );

  const storageFacilities =
    receiverStorageFacilitiesQuery.data?.businessEntityById.storageFacilities ||
    [];

  return (
    <div>
      <ReceiverForm
        shipmentLocation={receiverLocation}
        onChange={handleChange}
        storageFacilities={storageFacilities}
        storageFacilitiesLoading={
          !!receiverLocation.receiver &&
          receiverStorageFacilitiesQuery.isLoading
        }
        {...props}
      />
    </div>
  );
}
