import { Stack } from "@mui/material";
import { ComponentType } from "react";
import {
  CustomField,
  CustomFieldType,
  GetCustomFieldDefinitionsQuery,
} from "../../../graphql/generated";
import SelectCustomFieldInput from "./custom-field-inputs/SelectCustomFieldInput";
import StringCustomFieldInput from "./custom-field-inputs/StringCustomFieldInput";
import NumberCustomFieldInput from "./custom-field-inputs/NumberCustomFieldInput";
import MultiSelectCustomFieldInput from "./custom-field-inputs/MultiSelectCustomFieldInput";
import DateInput from "./custom-field-inputs/DateInput";
import TimeInput from "./custom-field-inputs/TimeInput";
import BooleanCustomFieldInput from "./custom-field-inputs/BooleanCustomFieldInput";

type CustomFieldDefinitionFormData =
  GetCustomFieldDefinitionsQuery["cutomFieldDefinitions"]["data"][0];

export type CustomFieldsFormProps = {
  customFieldDefinitions: CustomFieldDefinitionFormData[];
  customFields: CustomField[] | null;
  onChange: (customFields: CustomField[]) => void;
};

export type CustomFieldValue = string | number | boolean | string[] | number[];

export type CustomFieldInputProps = {
  value: CustomFieldValue;
  onChange: (value: CustomFieldValue | null) => void;
  customFieldDefinition: CustomFieldDefinitionFormData;
};

export type CustomFieldInputComponent = ComponentType<CustomFieldInputProps>;

const CustomFieldInputComponentByTarget: {
  [key in CustomFieldType]: CustomFieldInputComponent | null;
} = {
  [CustomFieldType.Select]: SelectCustomFieldInput,
  [CustomFieldType.Boolean]: BooleanCustomFieldInput,
  [CustomFieldType.Date]: DateInput,
  [CustomFieldType.Datetime]: null,
  [CustomFieldType.Multiselect]: MultiSelectCustomFieldInput,
  [CustomFieldType.Number]: NumberCustomFieldInput,
  [CustomFieldType.String]: StringCustomFieldInput,
  [CustomFieldType.Time]: TimeInput,
};

const CustomFieldsForm = ({
  customFields,
  customFieldDefinitions,
  onChange,
}: CustomFieldsFormProps) => {
  return (
    <Stack direction="column" spacing={2}>
      {customFieldDefinitions.map((customFieldDefinition) => {
        const InputComponent =
          CustomFieldInputComponentByTarget[customFieldDefinition.type];
        if (!InputComponent) {
          return null;
        }
        const customField = customFields?.find(
          (customField) => customField.key === customFieldDefinition.key
        );
        return (
          <InputComponent
            customFieldDefinition={customFieldDefinition}
            value={customField?.value || null}
            onChange={(value) => {
              if (!value) {
                onChange(
                  customFields?.filter(
                    (customField) =>
                      customField.key !== customFieldDefinition.key
                  ) || []
                );
                return;
              }
              onChange(
                (
                  customFields?.filter(
                    (customField) =>
                      customField.key !== customFieldDefinition.key
                  ) || []
                ).concat({
                  key: customFieldDefinition.key,
                  value,
                })
              );
            }}
          />
        );
      })}
    </Stack>
  );
};

export default CustomFieldsForm;
