import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { TransactionFormProps } from "../TransactionForm/TransactionForm";
import { TransactionInput } from "../../../../graphql/generated";
import TransactionFormContainer from "../TransactionForm";
import { useTranslation } from "react-i18next";
import ObjectID from "bson-objectid";

interface TransactionFormModalProps {
  isOpened: boolean;
  initialTransaction?: TransactionInput | null;
  title?: string;
  transactionType?: TransactionFormProps["transactionType"];
  isTemplate?: TransactionFormProps["isTemplate"];
  isFlatFee?: TransactionFormProps["isFlatFee"];
  onClose: () => void;
  onSubmit: (transaction: TransactionInput) => void;
  routeDistance?: number;
}

const newTransaction: TransactionInput = {
  label: "",
  rate: 1,
  unit: 1,
  unitType: "Units",
  taxable: true,
};

export default function TransactionFormModal({
  isOpened,
  initialTransaction,
  title,
  transactionType,
  onClose,
  onSubmit,
  routeDistance,
  isTemplate,
  isFlatFee,
}: TransactionFormModalProps) {
  const { t } = useTranslation(["finance", "common"]);
  //   const [errors, setErrors] = useState<Array<FormError>>([]);
  const [transaction, setTransaction] = useState<TransactionInput>(
    initialTransaction || newTransaction
  );

  useEffect(() => {
    if (initialTransaction) {
      setTransaction(initialTransaction);
    } else {
      setTransaction(newTransaction);
    }
  }, [initialTransaction]);

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit({
      ...transaction,
      _id: transaction._id || new ObjectID().toHexString(),
    });
    setTransaction({ ...newTransaction });
  };

  return (
    <Dialog maxWidth="md" fullWidth open={isOpened}>
      <DialogTitle variant="h6">
        {title || t("finance:addTransaction", "Add Transaction")}
      </DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <TransactionFormContainer
          transaction={transaction}
          onChange={setTransaction}
          transactionType={transactionType}
          routeDistance={routeDistance}
          isTemplate={isTemplate}
          isFlatFee={isFlatFee}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="inherit"
          variant="contained"
          onClick={handleCancel}
        >
          {t("common:cancel", "Cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          id="validate-charge-button"
        >
          {t("common:validate", "Validate")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
