import { CustomFieldInputComponent } from "../CustomFieldsForm";
import { Checkbox, FormControlLabel } from "@mui/material";

const BooleanCustomFieldInput: CustomFieldInputComponent = ({
  value,
  customFieldDefinition,
  onChange,
}) => {
  return (
    <FormControlLabel
      label={customFieldDefinition.label}
      control={
        <Checkbox
          checked={!!value}
          onChange={(event, checked) => {
            onChange(checked);
          }}
        />
      }
    />
  );
};

export default BooleanCustomFieldInput;
