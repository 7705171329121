import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import { FormDivider } from "../BusinessEntityForm/BusinessEntityForm";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  NewTractorInput,
  GetTractorDetailsQuery,
  AssetStatus,
  CustomFieldContext,
} from "../../../graphql/generated";
import AddressInput from "../AddressInput";
import { isArray, mergeWith, omit } from "lodash";
import tractorSchema from "./tractorSchema";
import LocaleProvider from "../../../providers/LocaleProvider";
import formatWeight from "../../../utils/labels/formatWeight";
import { localWeightToKg } from "../../../utils/conversion/weight";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import EnumSelect from "../../common/EnumSelect";
import { useTranslation } from "react-i18next";
import ChipTagsInput from "../../common/ChipTagsInput/ChipTagsInput";
import CustomFieldsFormContainer from "../../extensions/CustomFieldsForm";
import GroupSelectContainer from "../GroupSelect";

type Tractor = GetTractorDetailsQuery["tractorById"];

type TractorFormProps = {
  initialTractor?: Tractor;
  saving: boolean;
  onSave: (tractor: NewTractorInput) => void;
};
type PartialTractor = Partial<Tractor>;
type DeepPartialTractor = {
  [key in keyof PartialTractor]: Partial<PartialTractor[key]>;
};

const TractorForm = ({ initialTractor, saving, onSave }: TractorFormProps) => {
  const { t } = useTranslation(["assets", "common"]);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<Tractor> | null>(null);
  const [localTractor, setLocalTractor] = useState<Tractor | null>(
    initialTractor || null
  );

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialTractor) => {
    setLocalTractor((localTractor) =>
      mergeWith({}, localTractor, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = tractorSchema.validate(omit(localTractor, "_id"), {
      abortEarly: false,
    });
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localTractor]);

  const { cancelConfirm } = useConfirmBeforeLeave(localTractor);

  if (initialTractor && initialTractor.eldId) {
    return (
      <Alert severity="info">
        This tractor is managed by a third-party service.
      </Alert>
    );
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <FormDivider
            variant="fullWidth"
            text={t("assets:tractor.details", "Tractor Details")}
          />
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TextField
                label={t("assets:code", "Asset Code")}
                name="assetCode"
                required
                fullWidth
                value={localTractor?.serialNumber || ""}
                error={!!getFieldError("serialNumber")}
                helperText={getFieldError("serialNumber")}
                onChange={(event) => {
                  onChange({ serialNumber: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label="VIN#"
                name="vinNumber"
                fullWidth
                value={localTractor?.plateNumber || ""}
                error={!!getFieldError("plateNumber")}
                helperText={getFieldError("plateNumber")}
                onChange={(event) => {
                  onChange({ plateNumber: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:licenseNumber", "License Number")}
                name="licenseNumber"
                required
                fullWidth
                value={localTractor?.licenseNumber || ""}
                error={!!getFieldError("licenseNumber")}
                helperText={getFieldError("licenseNumber")}
                onChange={(event) => {
                  onChange({ licenseNumber: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:tractor.make", "Make")}
                name="make"
                fullWidth
                value={localTractor?.make || ""}
                error={!!getFieldError("make")}
                helperText={getFieldError("make")}
                onChange={(event) => {
                  onChange({ make: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:tractor.model", "Model")}
                name="model"
                fullWidth
                value={localTractor?.model || ""}
                error={!!getFieldError("model")}
                helperText={getFieldError("model")}
                onChange={(event) => {
                  onChange({ model: event.target.value });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:tractor.year", "Year")}
                name="year"
                fullWidth
                value={localTractor?.year || ""}
                error={!!getFieldError("year")}
                helperText={getFieldError("year")}
                onChange={(event) => {
                  onChange({ year: Number(event.target.value) });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                label={t("assets:tractor.tareWeightWithUnit", {
                  unit: LocaleProvider.getWeightUnit(),
                  defaultValue: `Tare Weight (${LocaleProvider.getWeightUnit()})`,
                })}
                name="tareWeight"
                fullWidth
                value={formatWeight(
                  Number(localTractor?.tareWeight || 0),
                  false
                )}
                error={!!getFieldError("tareWeight")}
                helperText={getFieldError("tareWeight")}
                onChange={(event) => {
                  onChange({
                    tareWeight: localWeightToKg(
                      !isNaN(Number.parseFloat(event.target.value))
                        ? Number.parseFloat(event.target.value)
                        : 0
                    ),
                  });
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <EnumSelect
                label={t("assets:status", "Status")}
                name="status"
                fullWidth
                enumObject={AssetStatus}
                error={!!getFieldError("status")}
                helperText={getFieldError("status")}
                value={localTractor?.status || AssetStatus.Active}
                onChange={(event, status) => {
                  if (!status) {
                    return;
                  }
                  onChange({
                    status,
                  });
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <ChipTagsInput
                value={localTractor?.tags || []}
                onChange={(tags) => {
                  onChange({ tags });
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <GroupSelectContainer
                value={localTractor?.groupIds || []}
                onChange={(groupIds) => {
                  onChange({ groupIds });
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={6}>
          <FormDivider
            variant="fullWidth"
            text={t("assets:domicile", "Domicile")}
          />
          <ErrorMessage message={getFieldError("domicile")} />

          <AddressInput
            value={localTractor?.domicile || null}
            onChange={(domicile) => {
              onChange({ domicile });
            }}
            errors={{
              label: getFieldError("domicile.label"),
              coordinates: getFieldError("domicile.coordinates"),
              line1: getFieldError("domicile.line1"),
              line2: getFieldError("domicile.line2"),
              postalCode: getFieldError("domicile.postalCode"),
              state: getFieldError("domicile.state"),
              city: getFieldError("domicile.city"),
              country: getFieldError("domicile.country"),
            }}
          />
        </Grid>

        <Grid item sm={6}>
          <FormDivider variant="fullWidth" text={t("common:customFields")} />

          <CustomFieldsFormContainer
            context={CustomFieldContext.Tractor}
            customFields={localTractor?.customFields || []}
            onChange={(customFields) => {
              onChange({ customFields });
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (!localTractor) {
              return;
            }
            if (validate()) {
              cancelConfirm();
              onSave(localTractor);
            }
          }}
          id="saveTractorButton"
        >
          {t("common:save", "Save")}
        </Button>
        <Box sx={{ mr: 1 }}>
          <ErrorMessage message={validationResult?.error?.message || null} />
        </Box>
      </Box>
    </Box>
  );
};

export default TractorForm;
