import { useCallback } from "react";
import { useParams } from "react-router-dom";
import BusinessEntityDetailsContainer from "../../../components/asset-management/BusinessEntityDetails";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { BusinessEntity } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";

function ViewSupplier() {
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (businessEntity: Pick<BusinessEntity, "code">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle label="Supplier" suffix={businessEntity?.code || ""} />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions]
  );
  if (!id) {
    return null;
  }
  return (
    <BusinessEntityDetailsContainer
      businessEntityId={id}
      onLoad={onLoad}
      urlPrefix="/suppliers"
    />
  );
}

export default ViewSupplier;
