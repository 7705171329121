import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  Stack,
  useTheme,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  GetTripPositionsQuery,
  GetTripQuery,
  Status,
  TrailerType,
} from "../../../graphql/generated";
import TripRoute from "../../trip-planning/TripPlanner/TripRoute";
import LoadingOverlay from "../../common/LoadingOverlay";
import { useMemo, useState } from "react";
import AssignmentModal from "../AssignmentModal";
import driverLabel from "../../../utils/labels/driverLabel";
import {
  Domain,
  LocalShipping,
  Person,
  Route,
  RvHookup,
  Style,
} from "@mui/icons-material";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerLabel from "../../../utils/labels/trailerLabel";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo";
import { PolylineF } from "@react-google-maps/api";
import toLatLng from "../../../utils/geo/toLaLng";
import TripSummaryContainer from "../TripSummary";
import EventReport, {
  EventReportData,
} from "../../tracking/EventReport/EventReport";
import { getAllEventReports } from "../../tracking/ShipmentTracker/ShipmentTracker";
import EmptyState from "../../common/EmptyState";
import ShipmentEventFormModalContainer from "../../tracking/ShipmentEventFormModal";
import carrierLabel from "../../../utils/labels/carrierLabel";
import { endpoint } from "../../../config/endpoint";
import LynksTabs from "../../common/LynksTabs";
import TripPaymentsContainer from "../../accounting/TripPayments";
import RoleGuard from "../../account/Access/RoleGuard";
import TripViolations from "./TripViolations";
import { formatMileage } from "../../../utils/labels/formatDistance";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import ChipTagsList from "../../common/ChipTagsList/ChipTagsList";

export interface TripDetailsProps {
  trip: Exclude<GetTripQuery["tripById"], null | undefined>;
  tripPositions?: GetTripPositionsQuery["tripPositions"];
  loading?: boolean;
  error?: Error | null;
  onDelete: () => void;
  onRefresh: () => void;
}

enum TripDetailsTab {
  DETAILS = "DETAILS",
  PAYMENTS = "PAYMENTS",
}

function TripDetails({
  trip,
  tripPositions,
  loading,
  error,
  onDelete,
  onRefresh,
}: TripDetailsProps) {
  const { t } = useTranslation([
    "trips",
    "business",
    "users",
    "assets",
    "common",
  ]);
  const [activeTab, setActiveTab] = useState(TripDetailsTab.DETAILS);

  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);

  const openAssignmentModal = () => {
    setAssignmentModalOpen(true);
  };
  const closeAssignmentModal = () => {
    setAssignmentModalOpen(false);
    onRefresh();
  };

  const theme = useTheme();

  const isPowerOnly = trip.shipments[0].trailerType === TrailerType.PowerOnly;
  const allShipmentEvents = trip.shipments
    .map((shipment) => shipment.events)
    .flat();
  const allEventReports: EventReportData[] = useMemo(() => {
    return trip.shipments
      .map((shipment) => getAllEventReports(shipment, trip, true).reverse())
      .flat();
  }, [trip]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  const tabs = [
    {
      value: TripDetailsTab.DETAILS,
      label: t("trips:details", "Details"),
    },
    {
      value: TripDetailsTab.PAYMENTS,
      label: t("trips:payments", "Payments"),
    },
  ];

  return (
    <Box>
      <LoadingOverlay loading={loading || false} />
      {error ? (
        <Alert severity="error">{error.message || "Unknown error"}</Alert>
      ) : null}

      <TripViolations trip={trip} />

      {trip.status === Status.Deleted ? (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {t("trips:tripCancelled", "This trip was cancelled")}
        </Alert>
      ) : null}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 2,
        }}
      >
        <Stack direction="row" spacing={4}></Stack>
        {trip.status !== Status.Deleted ? (
          <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
            <IconButton onClick={() => onDelete()} color="error">
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </RoleGuard>
        ) : null}
      </Box>
      {trip.status !== Status.Deleted ? (
        <LynksTabs
          tabs={tabs}
          onSelectTab={(tab) => setActiveTab(tab)}
          value={activeTab}
        />
      ) : null}
      {activeTab === TripDetailsTab.DETAILS ? (
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={4}>
            <InfoBlock title={t("trips:information", "Information")}>
              <Stack direction="column">
                <TripSummaryContainer
                  trip={trip}
                  onRefetch={() => {
                    onRefresh();
                  }}
                  readonly={trip.status === Status.Deleted}
                />
                <Stack direction="row" justifyContent="space-between">
                  {trip.totalDistance ? (
                    <LabeledInfo
                      title={t("trips:totalMileage", "Total mileage")}
                      value={formatMileage(trip.totalDistance)}
                      icon={<Route />}
                    />
                  ) : null}
                  {trip.loadedDistance ? (
                    <LabeledInfo
                      title={t("trips:loadedMileage", "Loaded mileage")}
                      value={formatMileage(trip.loadedDistance)}
                      icon={<LocalShipping />}
                    />
                  ) : null}
                </Stack>
              </Stack>

              <LabeledInfo
                title={t("common:tags")}
                value={<ChipTagsList value={trip.tags || []} />}
                icon={<Style />}
              />
            </InfoBlock>
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoBlock
              title={t("trips:assignment", "Assignment")}
              action={
                <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
                  <Button
                    variant="outlined"
                    color="lightPrimary"
                    size="large"
                    onClick={openAssignmentModal}
                    disabled={trip.status === Status.Deleted}
                  >
                    {t("trips:change", "Change")}
                  </Button>
                </RoleGuard>
              }
            >
              <Stack direction="row" flexWrap="wrap">
                {trip.carrier ? (
                  <LabeledInfo
                    title={capitalize(t("business:carrier.one", "Carrier"))}
                    value={carrierLabel(trip.carrier)}
                    icon={<Domain />}
                  />
                ) : (
                  <LabeledInfo
                    title={capitalize(t("users:driver.one", "Driver"))}
                    value={driverLabel(trip.driver)}
                    icon={<Person />}
                  />
                )}
                <LabeledInfo
                  title={capitalize(t("assets:tractor.one", "Tractor"))}
                  value={tractorLabel(trip.tractor)}
                  icon={<LocalShipping />}
                />
                <LabeledInfo
                  title={capitalize(t("assets:trailer.one", "Trailer"))}
                  value={
                    isPowerOnly ? "Power Only" : trailerLabel(trip.trailer)
                  }
                  icon={<RvHookup />}
                />
                <LabeledInfo
                  title="Pup Trailers"
                  value={
                    isPowerOnly
                      ? "Power Only"
                      : trip.additionalTrailers?.map(trailerLabel).join(", ")
                  }
                  icon={<RvHookup />}
                />
              </Stack>
            </InfoBlock>

            {trip.carrier ? (
              <Box
                sx={{
                  pt: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  href={`${endpoint}/carrier-settlement/ratecon/${trip._id}/download`}
                  target="_blank"
                  LinkComponent="a"
                >
                  {t("generateRatecon", "Generate Rate Confirmation")}
                </Button>
              </Box>
            ) : null}
          </Grid>

          <Grid item xs={12} md={4}>
            <InfoBlock title={t("eventsTitle", "Events")}>
              <Box maxHeight={300} overflow="auto">
                {allEventReports.length > 0 ? (
                  <List sx={{ pt: 0 }}>
                    {(allEventReports || []).map((eventReport) => {
                      const shipmentEvent = allShipmentEvents.find(
                        (e) => e?._id === eventReport._id
                      );
                      return (
                        <>
                          <EventReport
                            key={eventReport._id}
                            eventReport={eventReport}
                            onClick={() => {
                              if (trip.status === Status.Deleted) {
                                return;
                              }
                              if (shipmentEvent) {
                                setSelectedEventId(shipmentEvent._id);
                                setIsEditModalOpen(true);
                              }
                            }}
                            showShipmentLink={false}
                          />

                          <ShipmentEventFormModalContainer
                            isOpened={
                              isEditModalOpen &&
                              selectedEventId === eventReport._id
                            }
                            onClose={() => {
                              setIsEditModalOpen(false);
                              onRefresh();
                            }}
                            shipmentId={eventReport.shipment._id}
                            initialShipmentEvent={
                              shipmentEvent
                                ? {
                                    _id: shipmentEvent._id,
                                    type: shipmentEvent.type,
                                    date: shipmentEvent.date,
                                    location: shipmentEvent.coordinates || {
                                      latitude: 0,
                                      longitude: 0,
                                    },
                                    shipment: eventReport.shipment._id,
                                    trip: trip._id,
                                    shipmentLocation:
                                      shipmentEvent.shipmentLocation || "",
                                  }
                                : null
                            }
                          />
                        </>
                      );
                    })}
                  </List>
                ) : (
                  <EmptyState />
                )}
              </Box>
            </InfoBlock>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                pb: 2,
                height: 300,
              }}
            >
              <TripRoute trip={trip} showAssetLocation={true}>
                {tripPositions ? (
                  <PolylineF
                    path={tripPositions.map((position) =>
                      toLatLng(position.coords)
                    )}
                    options={{
                      strokeColor: theme.palette.accent.main,
                      strokeWeight: 4,
                    }}
                  />
                ) : null}
              </TripRoute>
            </Box>
          </Grid>
        </Grid>
      ) : null}

      {activeTab === TripDetailsTab.PAYMENTS ? (
        <TripPaymentsContainer tripId={trip._id} />
      ) : null}
      <AssignmentModal
        tripId={trip._id}
        open={assignmentModalOpen}
        onClose={closeAssignmentModal}
      />
    </Box>
  );
}

export default TripDetails;
