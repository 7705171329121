import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab, Tabs } from "@mui/material";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import DriverPaymentEditor from "../../../components/accounting/DriverPaymentEditor";
import DriverDetails from "../../../components/asset-management/DriverDetails";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { Driver } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";
import driverLabel from "../../../utils/labels/driverLabel";
import { useTranslation } from "react-i18next";

function ViewDriver() {
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (driver: Pick<Driver, "firstname" | "lastname">) => {
      setNavigationOptions({
        title: <HeaderTitle label="Driver" suffix={`${driverLabel(driver)}`} />,
        showBackIcon: true,
      });
    },
    [setNavigationOptions]
  );
  const [activeTab, setActiveTab] = useState("DETAILS");
  const { t } = useTranslation("common");
  if (!id) {
    return null;
  }
  return (
    <TabContext value={activeTab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          aria-label="Driver tabs"
        >
          <Tab label={t("details")} value="DETAILS" aria-label="Details" />
          <Tab label={t("payment")} value="PAYMENT" aria-label="Payment" />
        </Tabs>
      </Box>

      <TabPanel value="DETAILS">
        <DriverDetails onLoad={onLoad} driverId={id} />
      </TabPanel>

      <TabPanel value="PAYMENT">
        <DriverPaymentEditor driverId={id} />
      </TabPanel>
    </TabContext>
  );
}

export default ViewDriver;
