import { ListItemText, MenuItem, Select } from "@mui/material";
import { isArray } from "lodash";
import { TrailerType } from "../../../../graphql/generated";
import trailerTypeLabel from "../../../../utils/labels/trailerTypeLabel";
import { ConditionValueSelectorComponent } from "../ConditionForm";

const TrailerTypeSelect: ConditionValueSelectorComponent = ({
  value,
  onChange,
}) => {
  return (
    <Select
      label="Trailer Type"
      size="small"
      multiple
      value={isArray(value) ? (value as TrailerType[]) : []}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      renderValue={(selected) =>
        selected.map((trailerType) => trailerTypeLabel(trailerType)).join(", ")
      }
    >
      {Object.values(TrailerType).map((trailerType) => (
        <MenuItem key={trailerType} value={trailerType}>
          <ListItemText primary={trailerTypeLabel(trailerType)} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default TrailerTypeSelect;
