import { Stack, Tooltip } from "@mui/material";
import { MaintenanceTask } from "../../../graphql/generated";
import { Warning } from "@mui/icons-material";
import { differenceInDays } from "date-fns";
import { formatDateTime } from "../../../utils/labels/formatDateTime";

type MaintenanceTaskItem = Pick<
  MaintenanceTask,
  "_id" | "label" | "startDate" | "endDate" | "required" | "status"
>;

export type MaintenanceWarningsProps = {
  upcomingMaintenanceTasks: MaintenanceTaskItem[];
  currentMaintenanceTasks: MaintenanceTaskItem[];
};

const MaintenanceWarnings = ({
  upcomingMaintenanceTasks,
  currentMaintenanceTasks,
}: MaintenanceWarningsProps) => {
  if (!currentMaintenanceTasks.length && !upcomingMaintenanceTasks.length) {
    return null;
  }

  const upcomingMaintenanceTasksIn7days = upcomingMaintenanceTasks.filter(
    (task) => differenceInDays(new Date(task.startDate), new Date()) <= 7
  );

  return (
    <Stack direction="row" spacing={1}>
      {upcomingMaintenanceTasksIn7days.length
        ? upcomingMaintenanceTasksIn7days.map((task) => (
            <Tooltip
              title={`Upcoming ${task.label} on ${formatDateTime(
                task.startDate
              )}`}
              key={task._id}
            >
              <Warning color="warning" />
            </Tooltip>
          ))
        : null}
      {currentMaintenanceTasks.map((task) => (
        <Tooltip
          title={
            task.endDate
              ? `${task.label} from ${formatDateTime(
                  task.startDate
                )} to ${formatDateTime(task.endDate)}`
              : `${task.label} started on ${formatDateTime(task.startDate)}`
          }
          key={task._id}
        >
          <Warning color={task.required ? "error" : "warning"} />
        </Tooltip>
      ))}
    </Stack>
  );
};

export default MaintenanceWarnings;
