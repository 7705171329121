import { Link } from "@mui/material";
import { Shipment } from "../../../graphql/generated";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

type ShipmentLinkProps = {
  shipment: Pick<
    Shipment,
    | "_id"
    | "shipmentNumber"
    | "referenceNumbers"
    | "isFromSplit"
    | "isFromRotation"
  > & {
    parentShipment?: Pick<
      Exclude<Shipment["parentShipment"], null | undefined>,
      "_id"
    > | null;
  };
};

const ShipmentLink = ({ shipment }: ShipmentLinkProps) => {
  const { t } = useTranslation("orders");
  return (
    <Link
      onClick={(e) => e.stopPropagation()}
      href={
        (shipment.isFromSplit || shipment.isFromRotation) &&
        shipment.parentShipment
          ? `/orders/details/${shipment.parentShipment._id}`
          : `/orders/details/${shipment._id}`
      }
      sx={{
        textTransform: "capitalize",
      }}
    >
      {t("order")} {shipment.shipmentNumber}{" "}
      {shipment.referenceNumbers?.filter((v) => !isEmpty(v))?.length
        ? ` / ${shipment.referenceNumbers.join(", ")}`
        : ""}
    </Link>
  );
};

export default ShipmentLink;
