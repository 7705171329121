import ShipmentForm from "../ShipmentForm";
import { ShipmentFormProps } from "../ShipmentForm/ShipmentForm";

type EditShipmentFormProps = {
  onSubmit: ShipmentFormProps["onSubmit"];
  loading: boolean;
  isChildLoad: boolean;
  isSplit: boolean;
};

const EditShipmentForm = ({
  onSubmit,
  loading,
  isChildLoad,
  isSplit,
}: EditShipmentFormProps) => {
  return (
    <ShipmentForm
      loading={loading}
      editMode
      isChildLoad={isChildLoad}
      isSplit={isSplit}
      onSubmit={onSubmit}
    />
  );
};

export default EditShipmentForm;
