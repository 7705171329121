import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format, isValid, parse } from "date-fns";
import { CustomFieldInputComponent } from "../CustomFieldsForm";

const DateInput: CustomFieldInputComponent = ({
  value,
  onChange,
  customFieldDefinition,
}) => {
  const parsedDate = parse(String(value), "yyyy-MM-dd", new Date());
  return (
    <DatePicker
      label={customFieldDefinition.label}
      value={value && isValid(parsedDate) ? parsedDate : null}
      onChange={(value) => {
        if (!value) {
          return;
        }
        if (!isValid(value)) {
          return;
        }
        onChange(format(value, "yyyy-MM-dd"));
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default DateInput;
