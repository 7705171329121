import { Container } from "@mui/material";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import DriverForm from "../../../components/asset-management/DriverForm";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { Driver } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";

function EditDriver() {
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (driver: Pick<Driver, "license">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label="Edit Driver"
            suffix={driver?.license?.licenseNumber || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions]
  );
  if (!id) {
    return null;
  }
  return (
    <Container>
      <DriverForm driverId={id} onLoad={onLoad} />
    </Container>
  );
}

export default EditDriver;
