import {
  TripSortCriteria,
  AssetStatus,
  GetMaintenanceTasksQuery,
  TripAssetTypes,
  Scalars,
  MaintenanceTaskStatus,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
// import maintenanceTaskSchema from "../MaintenanceTaskForm/maintenanceTaskSchema";
// import maintenanceTasksMapping from "../../../utils/mappin-s/maintenanceTasks";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import { useState } from "react";
import { useRoles } from "../../account/Access/RoleGuard";
import { capitalize } from "lodash";
import MaintenanceTaskFormContainer from "../MaintenanceTaskForm";
import { Check } from "@mui/icons-material";
import enumLabel from "../../../utils/labels/enumLabel";

type MaintenanceTaskListItem =
  GetMaintenanceTasksQuery["maintenanceTasks"]["data"][0];

export type MaintenanceTaskListProps = {
  query: QueryFn<MaintenanceTaskListItem, "maintenanceTasks", TripSortCriteria>;
  deleteMutation: DeleteMutation;
  onRefresh: () => void;
  onComplete: (maintenanceTask: MaintenanceTaskListItem) => void;
  assetType: TripAssetTypes;
  assetId: Scalars["ObjectId"];
  // onBulkCreate: (data: NewMaintenanceTaskInput[]) => Promise<any>;
};

export default function MaintenanceTasksList({
  query,
  deleteMutation,
  onRefresh,
  assetType,
  assetId,
  onComplete,
}: // onBulkCreate,
MaintenanceTaskListProps) {
  // const { t } = useTranslation("users");
  const [formOpen, setFormOpen] = useState(false);
  const [selectedMaintenanceTask, setSelectedMaintenanceTask] =
    useState<MaintenanceTaskListItem | null>(null);

  const roles = useRoles();

  const hasWriteAccess = roles.some((role) =>
    ["Carrier Admin", "Manager", "Dispatcher"].includes(role)
  );

  const fields: TableField<MaintenanceTaskListItem, AssetStatus>[] = [
    {
      value: "label",
      label: "Label",
      type: "string",
    },
    {
      value: "startDate",
      label: "Start Date",
      type: "datetime",
    },
    {
      value: "endDate",
      label: "End Date",
      type: "datetime",
    },
    {
      value: (list) =>
        list.recurrence
          ? `${enumLabel(list.recurrence.frequency)} (${
              list.recurrence.interval
            })`
          : "",
      label: "Recurrence",
      type: "string",
    },
  ];

  const [status, setStatus] = useState<MaintenanceTaskStatus | null>(null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Tabs
          variant="standard"
          value={status}
          onChange={(event, value) => {
            setStatus(value);
          }}
        >
          <Tab label="Pending" value={null} />
          <Tab label="Completed" value={MaintenanceTaskStatus.Completed} />
        </Tabs>
        {/* <ExcelImporter
            mapping={maintenanceTasksMapping}
            schema={maintenanceTaskSchema}
            onBulkCreate={onBulkCreate}
          /> */}
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          id="new-maintenanceTask-button"
          onClick={() => {
            setFormOpen(true);
            setSelectedMaintenanceTask(null);
          }}
        >
          New Maintenance Task
        </Button>
      </Box>
      <SmartLynksTable
        query={query}
        dataKey="maintenanceTasks"
        fields={fields}
        onRecordClick={(maintenanceTask) => {
          if (!hasWriteAccess) return;
          setFormOpen(true);
          setSelectedMaintenanceTask(maintenanceTask);
        }}
        disableSearch
        additionalQueryVariables={{
          status: status,
        }}
        deleteMutation={hasWriteAccess ? deleteMutation : undefined}
        actions={[
          {
            label: "Complete",
            icon: <Check />,
            tooltip: "Complete Maintenance Task",
            onClick: (maintenanceTask) => {
              onComplete(maintenanceTask);
            },
            isApplicable(record) {
              return record.status !== MaintenanceTaskStatus.Completed;
            },
          },
        ]}
      />
      <Dialog
        open={formOpen}
        onClose={() => {
          setFormOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {capitalize(
            selectedMaintenanceTask
              ? "Edit Maintenance Task"
              : "New Maintenance Task"
          )}
        </DialogTitle>
        <DialogContent>
          {formOpen ? (
            <MaintenanceTaskFormContainer
              maintenanceTaskId={selectedMaintenanceTask?._id}
              onSaved={() => {
                setFormOpen(false);
                setSelectedMaintenanceTask(null);
                onRefresh();
              }}
              assetType={assetType}
              assetId={assetId}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
}
