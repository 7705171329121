import { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Stack, TextField } from "@mui/material";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  NewCustomFieldDefinitionInput,
  GetCustomFieldDefinitionDetailsQuery,
  GetDriverListQuery,
  GetBusinessEntityListQuery,
  CustomFieldContext,
  CustomFieldType,
} from "../../../graphql/generated";
import { isArray, mergeWith, omitBy } from "lodash";
// import customFieldDefinitionSchema from "./customFieldDefinitionSchema";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import EnumSelect from "../../common/EnumSelect";
import { FormDivider } from "../../asset-management/BusinessEntityForm/BusinessEntityForm";
import { useTranslation } from "react-i18next";
import enumLabel from "../../../utils/labels/enumLabel";
import LabeledAddButton from "../../common/LabeledAddButton";
import { Delete } from "@mui/icons-material";
import customFieldContextLabel from "../../../utils/labels/customFieldContextLabel";

type CustomFieldDefinition =
  GetCustomFieldDefinitionDetailsQuery["customFieldDefinitionById"];

type CustomFieldDefinitionFormProps = {
  initialCustomFieldDefinition?: CustomFieldDefinition;
  drivers: GetDriverListQuery["drivers"]["data"];
  domiciles: GetBusinessEntityListQuery["businessEntities"]["data"];
  saving: boolean;
  onSave: (customFieldDefinition: NewCustomFieldDefinitionInput) => void;
};
type PartialCustomFieldDefinition = Partial<CustomFieldDefinition>;
type DeepPartialCustomFieldDefinition = {
  [key in keyof PartialCustomFieldDefinition]: Partial<
    PartialCustomFieldDefinition[key]
  >;
};

const CustomFieldDefinitionForm = ({
  initialCustomFieldDefinition,
  saving,
  onSave,
  drivers,
  domiciles,
}: CustomFieldDefinitionFormProps) => {
  const { t } = useTranslation(["users", "common"]);
  const [validationResult] =
    useState<ValidationResult<CustomFieldDefinition> | null>(null);
  const [localCustomFieldDefinition, setLocalCustomFieldDefinition] = useState<
    Partial<NewCustomFieldDefinitionInput>
  >(initialCustomFieldDefinition || {});

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialCustomFieldDefinition) => {
    setLocalCustomFieldDefinition((localCustomFieldDefinition) =>
      mergeWith(
        {},
        localCustomFieldDefinition,
        changes,
        (objValue, srcValue) => {
          if (isArray(srcValue)) {
            return srcValue;
          }
        }
      )
    );
  }, []);

  const validate = () => {
    // const validationResult = customFieldDefinitionSchema.validate(
    //   omit(localCustomFieldDefinition, "_id"),
    //   {
    //     abortEarly: false,
    //   }
    // );
    // setValidationResult(validationResult);
    // return !validationResult.error;
    return true;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCustomFieldDefinition]);

  const { cancelConfirm } = useConfirmBeforeLeave(localCustomFieldDefinition);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <FormDivider variant="fullWidth" text="Custom Field Details" />
          <ErrorMessage message={validationResult?.error?.message} />
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <EnumSelect
                enumObject={omitBy(CustomFieldContext, (context) =>
                  [
                    CustomFieldContext.Expense,
                    CustomFieldContext.Trip,
                  ].includes(context)
                )}
                label={"Entities"}
                required
                fullWidth
                multiple
                value={localCustomFieldDefinition.context || []}
                error={!!getFieldError("days")}
                helperText={getFieldError("days")}
                optionLabel={customFieldContextLabel}
                onChange={(event, value) => {
                  onChange({ context: value });
                }}
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                label={t("common:label", "Label")}
                fullWidth
                required
                value={localCustomFieldDefinition.label || ""}
                error={!!getFieldError("label")}
                helperText={getFieldError("label")}
                onChange={(event) => {
                  onChange({ label: event.target.value });
                }}
              />
            </Grid>

            <Grid item sm={6}>
              <EnumSelect
                enumObject={CustomFieldType}
                label={t("common:type", "Type")}
                required
                fullWidth
                value={localCustomFieldDefinition.type || ""}
                error={!!getFieldError("type")}
                helperText={getFieldError("type")}
                optionLabel={(type) => enumLabel(type) || type}
                onChange={(event, value) => {
                  if (!value) {
                    return;
                  }
                  onChange({ type: value });
                }}
              />
            </Grid>

            {localCustomFieldDefinition.type === CustomFieldType.Select ||
            localCustomFieldDefinition.type === CustomFieldType.Multiselect ? (
              <>
                <Grid item sm={12}>
                  <FormDivider text="Options" variant="fullWidth" />
                  <Stack spacing={1}>
                    {localCustomFieldDefinition.selectOptions?.map(
                      (option, index) => (
                        <Stack direction="row">
                          <TextField
                            label={t("common:option", "Option")}
                            fullWidth
                            required
                            value={option.label}
                            error={!!getFieldError("selectOptions")}
                            helperText={getFieldError("selectOptions")}
                            onChange={(event) => {
                              const newOptions = [
                                ...(localCustomFieldDefinition.selectOptions ||
                                  []),
                              ];
                              newOptions[index] = {
                                label: event.target.value,
                                key: event.target.value
                                  .toLowerCase()
                                  .replace(" ", "_"),
                              };
                              onChange({ selectOptions: newOptions });
                            }}
                          />
                          <IconButton
                            onClick={() => {
                              const newOptions = [
                                ...(localCustomFieldDefinition.selectOptions ||
                                  []),
                              ];
                              newOptions.splice(index, 1);
                              onChange({ selectOptions: newOptions });
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Stack>
                      )
                    )}
                    <LabeledAddButton
                      label="Add Option"
                      onClick={() => {
                        onChange({
                          selectOptions: [
                            ...(localCustomFieldDefinition.selectOptions || []),
                            {
                              label: "",
                              key: "",
                            },
                          ],
                        });
                      }}
                    />
                  </Stack>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (!localCustomFieldDefinition) {
              return;
            }
            if (validate()) {
              cancelConfirm();
              onSave(
                localCustomFieldDefinition as NewCustomFieldDefinitionInput
              );
            }
          }}
          id="saveCustomFieldDefinitionButton"
        >
          {t("common:save", "Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default CustomFieldDefinitionForm;
