import { Container } from "@mui/material";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import BusinessEntityForm from "../../../components/asset-management/BusinessEntityForm";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { BusinessEntity, BusinessEntityType } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";

function EditBusinessLocation() {
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (businessEntity: Pick<BusinessEntity, "code">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label="Edit Location"
            suffix={businessEntity?.code || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions]
  );
  if (!id) {
    return null;
  }
  return (
    <Container>
      <BusinessEntityForm
        businessEntityId={id}
        onLoad={onLoad}
        urlPrefix="/business-locations"
        availableTypes={[
          BusinessEntityType.Shipper,
          BusinessEntityType.Receiver,
          BusinessEntityType.Terminal,
          BusinessEntityType.TruckStop,
          BusinessEntityType.GeneralLocation,
          BusinessEntityType.Port,
          BusinessEntityType.Domicile,
        ]}
      />
    </Container>
  );
}

export default EditBusinessLocation;
