import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import UserDetails from "../../components/account/UserDetails";
import { useNavigationOptions } from "../LayoutRoutes";
import { OrgUser } from "../../graphql/generated";
import HeaderTitle from "../../components/common/HeaderTitle";
import userLabel from "../../utils/labels/userLabel";

function ViewUser() {
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (user: Pick<OrgUser, "firstName" | "lastName">) => {
      setNavigationOptions({
        title: <HeaderTitle label="User" suffix={`${userLabel(user)}`} />,
        showBackIcon: true,
      });
    },
    [setNavigationOptions]
  );
  const [activeTab, setActiveTab] = useState("DETAILS");
  if (!id) {
    return null;
  }
  return (
    <TabContext value={activeTab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={(event, value) => setActiveTab(value)}
          aria-label="User tabs"
        >
          <Tab label="Details" value="DETAILS" />
          {/* <Tab label="Credentials" value="CREDENTIALS" /> */}
        </TabList>
      </Box>

      <TabPanel value="DETAILS">
        <UserDetails onLoad={onLoad} userId={id} />
      </TabPanel>

      {/* <TabPanel value="CREDENTIALS">Credentials</TabPanel> */}
    </TabContext>
  );
}

export default ViewUser;
