import {
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { ComponentType } from "react";
import {
  BillingRuleCondition,
  BillingRuleConditionOperator,
  BillingRuleConditionTarget,
  BusinessEntityType,
  GetCustomerDetailsQuery,
} from "../../../graphql/generated";
import billingRuleConditionOperatorLabel from "../../../utils/labels/billingRule/billingRuleConditionOperatorLabel";
import billingRuleConditionTargetLabel from "../../../utils/labels/billingRule/billingRuleConditionTargetLabel";
import BusinessEntityInput from "./value-selectors/BusinessEntityInput";
import DateInput from "./value-selectors/DateInput";
import DurationInput from "./value-selectors/DurationInput";
import GoodsInput from "./value-selectors/GoodsInput";
import MileageInput from "./value-selectors/MileageInput";
import TimeInput from "./value-selectors/TimeInput";
import TrailerInput from "./value-selectors/TrailerInput";
import TrailerTypeSelect from "./value-selectors/TrailerTypeSelect";
import { useTranslation } from "react-i18next";

export type ConditionValueSelectorComponentProps = {
  value: number | string | string[] | null;
  onChange: (value: number | string | string[] | null) => void;
  customer?: GetCustomerDetailsQuery["customerById"];
};

export type ConditionValueSelectorComponent =
  ComponentType<ConditionValueSelectorComponentProps>;

const ConditionValueSelectorComponentByTarget: {
  [key in BillingRuleConditionTarget]: ConditionValueSelectorComponent | null;
} = {
  [BillingRuleConditionTarget.TrailerType]: TrailerTypeSelect,
  [BillingRuleConditionTarget.Date]: DateInput,
  [BillingRuleConditionTarget.Otherwise]: null,
  [BillingRuleConditionTarget.Mileage]: MileageInput,
  [BillingRuleConditionTarget.Receiver]: (
    props: ConditionValueSelectorComponentProps
  ) => (
    <BusinessEntityInput
      {...props}
      businessEntityType={BusinessEntityType.Receiver}
    />
  ),
  [BillingRuleConditionTarget.Shipper]: (
    props: ConditionValueSelectorComponentProps
  ) => (
    <BusinessEntityInput
      {...props}
      businessEntityType={BusinessEntityType.Shipper}
    />
  ),
  [BillingRuleConditionTarget.Time]: TimeInput,
  [BillingRuleConditionTarget.Trailer]: TrailerInput,
  [BillingRuleConditionTarget.DetentionTime]: DurationInput,
  [BillingRuleConditionTarget.Goods]: GoodsInput,
};

type ConditionFormProps = {
  condition: BillingRuleCondition;
  onChange: (condition: BillingRuleCondition) => void;
  customer: GetCustomerDetailsQuery["customerById"];
};

const targetOperatorConstraints: {
  [key: string]: BillingRuleConditionOperator[] | null;
} = {
  [BillingRuleConditionTarget.Shipper]: [
    BillingRuleConditionOperator.Equals,
    BillingRuleConditionOperator.NotEquals,
  ],
  [BillingRuleConditionTarget.Receiver]: [
    BillingRuleConditionOperator.Equals,
    BillingRuleConditionOperator.NotEquals,
  ],
  [BillingRuleConditionTarget.TrailerType]: [
    BillingRuleConditionOperator.Equals,
    BillingRuleConditionOperator.NotEquals,
  ],
  [BillingRuleConditionTarget.Trailer]: [
    BillingRuleConditionOperator.Equals,
    BillingRuleConditionOperator.NotEquals,
  ],
};

const ConditionForm = ({
  condition,
  customer,
  onChange,
}: ConditionFormProps) => {
  const ValueSelectorComponent =
    ConditionValueSelectorComponentByTarget[condition.target];
  const { t } = useTranslation("billingRules");
  return (
    <Stack direction="row" alignItems="center" spacing={1} flex={1}>
      <Select
        label="Condition"
        size="small"
        value={condition.target}
        onChange={(event) => {
          onChange({
            ...condition,
            target: event.target.value as BillingRuleConditionTarget,
            value: null,
            operator: BillingRuleConditionOperator.Equals,
          });
        }}
      >
        {Object.values(BillingRuleConditionTarget).map((target) => (
          <MenuItem key={target} value={target}>
            <ListItemText primary={billingRuleConditionTargetLabel(target)} />
          </MenuItem>
        ))}
      </Select>

      {condition.target &&
      condition.target !== BillingRuleConditionTarget.Otherwise ? (
        <Select
          label="Operator"
          size="small"
          value={condition.operator}
          onChange={(event) => {
            const operator = event.target.value as BillingRuleConditionOperator;
            onChange({
              ...condition,
              operator,
              value:
                operator === BillingRuleConditionOperator.IsBetween
                  ? [condition.value, null]
                  : condition.value,
            });
          }}
        >
          {(
            targetOperatorConstraints[condition.target] ||
            Object.values(BillingRuleConditionOperator)
          ).map((operator) => (
            <MenuItem key={operator} value={operator}>
              <ListItemText
                primary={billingRuleConditionOperatorLabel(operator)}
              />
            </MenuItem>
          ))}
        </Select>
      ) : null}
      {condition.target &&
      condition.operator &&
      condition.operator !== BillingRuleConditionOperator.IsBetween &&
      ValueSelectorComponent ? (
        <ValueSelectorComponent
          customer={customer}
          value={condition.value}
          onChange={(value) =>
            onChange({
              ...condition,
              value,
            })
          }
        />
      ) : null}
      {condition.target &&
      condition.operator === BillingRuleConditionOperator.IsBetween &&
      ValueSelectorComponent ? (
        <>
          <ValueSelectorComponent
            customer={customer}
            value={condition.value[0]}
            onChange={(value) =>
              onChange({
                ...condition,
                value: [value, condition.value[1]],
              })
            }
          />

          <Typography variant="body2">{t("form.and")}</Typography>
          <ValueSelectorComponent
            customer={customer}
            value={condition.value[1]}
            onChange={(value) =>
              onChange({
                ...condition,
                value: [condition.value[0], value],
              })
            }
          />
        </>
      ) : null}
    </Stack>
  );
};

export default ConditionForm;
