import { Container } from "@mui/material";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import GoodProfileForm from "../../../components/commodity-management/GoodProfileForm";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { GoodProfile } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";

function EditGoodProfile() {
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (goodProfile: Pick<GoodProfile, "code">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle
            label="Edit Commodity"
            suffix={goodProfile?.code || ""}
          />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions]
  );
  if (!id) {
    return null;
  }
  return (
    <Container>
      <GoodProfileForm goodProfileId={id} onLoad={onLoad} />
    </Container>
  );
}

export default EditGoodProfile;
