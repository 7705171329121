import Joi from "joi";
import { DriverLicense, DriverMedical } from "../../../graphql/generated";
import { requiredTextualAddressSchema } from "../../../utils/schemas/textualAddressSchema";
import { FormDriver } from "./DriverForm";
import customFieldSchema from "../../extensions/CustomFieldsForm/customFieldsSchema";

const driverLicenseSchema = Joi.object<DriverLicense>({
  licenseNumber: Joi.string().label("License Number").required(),
  issuedState: Joi.string().label("License issued state").required(),
  issuedDate: Joi.date().label("License issued date").required(),
  expiryDate: Joi.date()
    .greater(Joi.ref("issuedDate"))
    .label("License expiry date")
    .required(),
  classification: Joi.string().label("License classification").required(),
  restriction: Joi.string().label("License restriction").allow(null),
  endorsements: Joi.array()
    .items(Joi.string())
    .label("License endorsements")
    .allow(null),
  address: requiredTextualAddressSchema
    .required()
    .disallow(null)
    .label("License address"),
});

const driverMedicalSchema = Joi.object<DriverMedical>({
  number: Joi.string().label("Medical Number").allow(null, ""),
  issuedDate: Joi.date().label("Issued date").allow(null),
  expiryDate: Joi.date()
    .greater(Joi.ref("issuedDate"))
    .label("Expiry date")
    .allow(null),
  restriction: Joi.string().label("Restriction").allow(null, ""),
});

const driverSchema = Joi.object<FormDriver>({
  _id: Joi.string().allow(null),
  firstname: Joi.string().label("First name").required(),
  middlename: Joi.string().label("First name").allow(null, ""),
  lastname: Joi.string().label("Last name").required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .label("Email")
    .allow(null, ""),
  username: Joi.alternatives().conditional("password", {
    then: Joi.string().label("Email").min(3).max(40).required(),
    otherwise: Joi.allow("", null),
  }),
  password: Joi.alternatives().conditional("username", {
    then: Joi.alternatives().conditional("_id", {
      then: Joi.string().label("Password").allow(null, ""),
      otherwise: Joi.string().label("Password").required(),
    }),
    otherwise: Joi.string().label("Password").allow("", null),
  }),
  domicileId: Joi.string().allow(null),
  dateOfBirth: Joi.date().label("Date of birth").required(),
  dateOfPhysicalExamination: Joi.date()
    .greater(Joi.ref("dateOfBirth"))
    .label("Date of physical examination")
    .required(),
  phoneNumber: Joi.string().label("Phone number").required(),
  documents: Joi.array()
    .items({
      _id: Joi.string().allow("", null),
      name: Joi.string().allow("", null).label("document name"),
      url: Joi.string().allow("", null).label("document url"),
    })
    .label("Documents")
    .allow(null, ""),
  license: driverLicenseSchema.required().disallow(null).label("License"),
  medical: driverMedicalSchema.allow(null).label("Medical"),
  status: Joi.string().allow(null),
  userId: Joi.string().allow(null),
  isOwnerOperator: Joi.boolean()
    .label("Is owner operator")
    .default(false)
    .allow(null),
  eldId: Joi.string().allow(null),
  tags: Joi.array().items(Joi.string()).label("Tags").allow(null),
  customFields: Joi.array()
    .items(customFieldSchema)
    .label("Custom Fields")
    .allow(null),
  groupIds: Joi.array().items(Joi.string()).label("Group IDs").allow(null),
});

export default driverSchema;
