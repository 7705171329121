import { useEffect } from "react";
import PlanningToolContainer from "../../components/trip-planning/PlanningTool";
import { useNavigationOptions } from "../LayoutRoutes";

export default function Planning() {
  const { setNavigationOptions } = useNavigationOptions();

  useEffect(() => {
    setNavigationOptions({
      title: "Planning",
      showBackIcon: true,
      headerSize: "small",
    });
  }, [setNavigationOptions]);

  return <PlanningToolContainer />;
}
