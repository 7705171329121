import {
  TripSortCriteria,
  AssetStatus,
  GetCustomFieldDefinitionsQuery,
} from "../../../graphql/generated";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
// import customFieldDefinitionSchema from "../CustomFieldDefinitionForm/customFieldDefinitionSchema";
// import customFieldDefinitionsMapping from "../../../utils/mappin-s/customFieldDefinitions";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import CustomFieldDefinitionFormContainer from "../CustomFieldDefinitionForm";
import { useRoles } from "../../account/Access/RoleGuard";
import { capitalize } from "lodash";
import customFieldContextLabel from "../../../utils/labels/customFieldContextLabel";

export type CustomFieldDefinitionListItem =
  GetCustomFieldDefinitionsQuery["cutomFieldDefinitions"]["data"][0];

export default function CustomFieldDefinitionsList({
  query,
  deleteMutation,
  onRefresh,
}: // onBulkCreate,
{
  query: QueryFn<
    CustomFieldDefinitionListItem,
    "cutomFieldDefinitions",
    TripSortCriteria
  >;
  deleteMutation: DeleteMutation;
  onRefresh: () => void;
  // onBulkCreate: (data: NewCustomFieldDefinitionInput[]) => Promise<any>;
}) {
  // const { t } = useTranslation("users");
  const [formOpen, setFormOpen] = useState(false);
  const [selectedCustomFieldDefinition, setSelectedCustomFieldDefinition] =
    useState<CustomFieldDefinitionListItem | null>(null);

  const roles = useRoles();

  const hasWriteAccess = roles.some((role) =>
    ["Carrier Admin", "Manager", "Dispatcher"].includes(role)
  );

  const fields: TableField<CustomFieldDefinitionListItem, AssetStatus>[] = [
    {
      value: (item) => item.context.map(customFieldContextLabel).join(", "),
      label: "Entities",
      type: "string",
    },
    {
      value: "label",
      label: "Label",
      type: "string",
    },
    {
      value: "type",
      label: "Type",
      type: "string",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignSelf: "flex-end",
          mb: 2,
        }}
      >
        {/* <ExcelImporter
          mapping={customFieldDefinitionsMapping}
          schema={customFieldDefinitionSchema}
          onBulkCreate={onBulkCreate}
        /> */}
        <Button
          variant="contained"
          color="secondary"
          size="large"
          id="new-customFieldDefinition-button"
          onClick={() => {
            setFormOpen(true);
            setSelectedCustomFieldDefinition(null);
          }}
        >
          New Custom Field
        </Button>
      </Box>
      <SmartLynksTable
        query={query}
        dataKey="cutomFieldDefinitions"
        fields={fields}
        onRecordClick={(customFieldDefinition) => {
          if (!hasWriteAccess) return;
          setFormOpen(true);
          setSelectedCustomFieldDefinition(customFieldDefinition);
        }}
        deleteMutation={hasWriteAccess ? deleteMutation : undefined}
      />
      <Dialog
        open={formOpen}
        onClose={() => {
          setFormOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {capitalize(
            selectedCustomFieldDefinition
              ? "Edit Custom Field"
              : "New Custom Field"
          )}
        </DialogTitle>
        <DialogContent>
          {formOpen ? (
            <CustomFieldDefinitionFormContainer
              customFieldDefinitionId={selectedCustomFieldDefinition?._id}
              onSaved={() => {
                setFormOpen(false);
                onRefresh();
              }}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
}
