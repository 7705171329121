import { useState } from "react";
import {
  useGenerateReportPdfMutation,
  useGetDriverListQuery,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import ReportGenerator from "./ReportGenerator";
import { useTranslation } from "react-i18next";

const ReportGeneratorContainer = () => {
  const { t } = useTranslation("common");
  const [generating, setGenerating] = useState(false);
  const generateReportPdfMutation = useGenerateReportPdfMutation();
  const { showDialog } = useDialog();
  const getDriversQuery = useGetDriverListQuery();
  return (
    <ReportGenerator
      drivers={getDriversQuery.data?.drivers.data || []}
      generating={generating}
      onGenerateAndDownloadReport={async (reportType, period, filter) => {
        try {
          setGenerating(true);
          const result = await generateReportPdfMutation.mutateAsync({
            reportType,
            period,
            filter,
          });
          await new Promise(async (resolve, reject) => {
            async function checkFileReady(retryCount = 0) {
              try {
                const response = await fetch(result.generateReportPdf.url);
                if (response.status === 404) {
                  if (retryCount > 10) {
                    reject(
                      new Error(
                        t("error.reportTimeout", "Timeout waiting for report")
                      )
                    );
                  }
                  setTimeout(() => checkFileReady(retryCount + 1), 1000);
                } else {
                  resolve(null);
                }
              } catch (error) {
                reject(error);
              }
            }
            setTimeout(checkFileReady, 1000);
          });
          window.open(result.generateReportPdf.url, "_blank");
        } catch (error) {
          showDialog({
            type: "error",
            title: t("error.generateReportError", "Error generating report"),
            description:
              (error as Error).message ||
              t("error.unknownError", "Unknown error"),
          });
        } finally {
          setGenerating(false);
        }
      }}
    />
  );
};

export default ReportGeneratorContainer;
