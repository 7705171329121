import "./timeline-resources.css";

import {
  GetAllDriverShiftsQuery,
  GetAllMaintenanceTasksQuery,
  GetAssignmentAssetsQuery,
  GetBusinessEntityListQuery,
  GetShipmentListForPlanningQuery,
  ShipmentSortCriteria,
} from "../../../graphql/generated";
import { Badge, Box, Button, Fab, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { DragHandle, FilterAlt } from "@mui/icons-material";
import { AssetFiltersValue } from "./AssetFilters";
import { LoadFiltersValue } from "./LoadFilters";
import PlanningGrid from "./PlanningGrid";
import PlanningCalendar from "./PlanningCalendar";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { debounce, isEmpty, omitBy } from "lodash";
import AssetFiltersModal from "./AssetFiltersModal";
import { useTranslation } from "react-i18next";

export type Assignment = {
  shipmentId: string;
  driverId: string;
  startTime: Date;
};

export type AugmentedShipment =
  GetShipmentListForPlanningQuery["shipments"]["data"][0] & {
    originState: string | null;
    destinationState: string | null;
    originCity: string | null;
    destinationCity: string | null;
    earliestPickup: Date;
    latestPickup: Date;
    earliestDropoff: Date;
    latestDropoff: Date;
    otherShipmentsInTrip: AugmentedShipment[];
    previousPickupTime?: Date | null;
  };

export type AugmentedDriver = GetAssignmentAssetsQuery["drivers"]["data"][0] & {
  associatedTractor: GetAssignmentAssetsQuery["assetLinkings"]["data"][0]["tractor"];
  associatedTrailer: GetAssignmentAssetsQuery["assetLinkings"]["data"][0]["trailer"];
  firstShipmentInPeriod: AugmentedShipment | null;
  lastShipmentInPeriod: AugmentedShipment | null;
};

export type PlanningToolProps = {
  drivers: AugmentedDriver[];
  tractors: GetAssignmentAssetsQuery["tractors"]["data"];
  trailers: GetAssignmentAssetsQuery["trailers"]["data"];
  shipments: AugmentedShipment[];
  filteredShipments: AugmentedShipment[];
  driverShifts: GetAllDriverShiftsQuery["driverShifts"]["data"];
  maintenanceTasks: GetAllMaintenanceTasksQuery["maintenanceTasks"]["data"];
  domiciles: GetBusinessEntityListQuery["businessEntities"]["data"];
  assetFilters: AssetFiltersValue;
  loadFilters: LoadFiltersValue;
  driverIdBeingPlanned?: string | null;
  onPeriodChange: (period: { start: Date; end: Date }) => void;
  onAssetSearchChange: (search: string) => void;
  onAssetFiltersChange: (filters: AssetFiltersValue) => void;
  onLoadFiltersChange: (filters: LoadFiltersValue) => void;
  onLoadSearchChange: (search: string) => void;
  onSortChange: (sorCriteria: ShipmentSortCriteria) => void;
  onSortOrderDescChange: (sortDesc: boolean) => void;
  onShipmentsChange: (
    shipments: AugmentedShipment[],
    changedDriver?: string
  ) => void;
  onShipmentsCombine: (shipments: AugmentedShipment[]) => void;
  onRefresh: (shipmentIds?: string[]) => Promise<void>;
  onSubmit: () => Promise<void>;
};

const PlanningTool = ({
  onPeriodChange,
  onSortChange,
  onSortOrderDescChange,
  onRefresh,
  ...props
}: PlanningToolProps) => {
  const { t } = useTranslation(["planning", "common"]);
  const [assetFilterDialogOpen, setAssetFilterDialogOpen] = useState(false);

  const [calendarPanelSize, setCalendarPanelSize] = useState(0);
  const setCalendarPanelSizeDebounced = debounce(setCalendarPanelSize, 300);

  const [selectedShipments, setSelectedShipments] = useState<
    AugmentedShipment[]
  >([]);

  const [showCalendar, setShowCalendar] = useState(true);

  const redrawCalendar = () => {
    setShowCalendar(false);
    setTimeout(() => {
      setShowCalendar(true);
    }, 0);
  };

  const handleRefresh = useCallback(
    async (shipmentIds?: string[]) => {
      await onRefresh(shipmentIds);
      redrawCalendar();
    },
    [onRefresh]
  );

  const headerIndentTemplate = useCallback(() => {
    return (
      <Stack
        direction="row"
        alignItems="start"
        spacing={1}
        sx={{
          p: 1,
        }}
      >
        <Badge
          badgeContent={Object.keys(omitBy(props.assetFilters, isEmpty)).length}
          color="primary"
        >
          <Button
            variant="outlined"
            size="large"
            onClick={() => {
              setAssetFilterDialogOpen(true);
            }}
          >
            <FilterAlt />
          </Button>
        </Badge>
      </Stack>
    );
  }, [props.assetFilters]);

  const onShipmentsSelect = useCallback((shipments: AugmentedShipment[]) => {
    setSelectedShipments(shipments);
  }, []);

  return (
    <Box sx={{ height: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Box>
          <AssetFiltersModal
            open={assetFilterDialogOpen}
            onClose={() => setAssetFilterDialogOpen(false)}
            filters={props.assetFilters}
            onChange={props.onAssetFiltersChange}
            domiciles={props.domiciles}
          />
        </Box>
      </Stack>

      <Box
        sx={{
          height: "calc(100%)",
        }}
      >
        <PanelGroup
          direction="vertical"
          style={{ height: "100%", overflow: "visible" }}
          autoSaveId="PlanningTool::MainPanelGroup"
        >
          <Panel style={{ overflow: "auto" }} defaultSize={40} minSize={10}>
            <PlanningGrid
              {...props}
              onShipmentsSelect={onShipmentsSelect}
              onRefresh={handleRefresh}
            />
          </Panel>

          <PanelResizeHandle
            style={{
              height: 24,
              textAlign: "center",
            }}
          >
            <DragHandle />
          </PanelResizeHandle>
          <Panel
            defaultSize={60}
            minSize={10}
            onResize={(size) => {
              setCalendarPanelSizeDebounced(size);
            }}
            style={
              {
                // overflow: "visible",
              }
            }
          >
            <div className="schedule-container" style={{ height: "100%" }}>
              {showCalendar ? (
                <PlanningCalendar
                  {...props}
                  onPeriodChange={onPeriodChange}
                  selectedShipmentId={
                    selectedShipments.length === 1
                      ? selectedShipments[0]._id
                      : null
                  }
                  parentHeight={calendarPanelSize}
                  headerIndentTemplate={headerIndentTemplate}
                  onRefresh={handleRefresh}
                />
              ) : null}
            </div>
          </Panel>
        </PanelGroup>
      </Box>

      <Fab
        sx={{
          position: "absolute",
          bottom: 32,
          right: 32,
          width: "auto",
          py: 3,
          px: 3,
          borderRadius: 16,
        }}
        color="primary"
        onClick={async () => {
          await props.onSubmit();
          redrawCalendar();
        }}
      >
        {t("common:save")}
      </Fab>
    </Box>
  );
};

export default PlanningTool;
