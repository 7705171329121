import { Language, MeasurementSystem } from "../graphql/generated";

export enum Locale {
  FR = "fr",
  EN = "en",
  EN_US = "en-US",
}

const languageToLocale: { [key in Language]: Locale } = {
  [Language.En]: Locale.EN_US,
  [Language.Fr]: Locale.FR,
};

const localeToMeasurementSystem: { [key in Locale]: MeasurementSystem } = {
  [Locale.FR]: MeasurementSystem.Metric,
  [Locale.EN]: MeasurementSystem.Metric,
  [Locale.EN_US]: MeasurementSystem.Imperial,
};

const measurementSystemToWeightUnit: { [key in MeasurementSystem]: string } = {
  [MeasurementSystem.Metric]: "kg",
  [MeasurementSystem.Imperial]: "lb",
};

const measurementSystemToDistanceUnit: { [key in MeasurementSystem]: string } =
  {
    [MeasurementSystem.Metric]: "m",
    [MeasurementSystem.Imperial]: "ft",
  };

const measurementSystemToMileageUnit: { [key in MeasurementSystem]: string } = {
  [MeasurementSystem.Metric]: "km",
  [MeasurementSystem.Imperial]: "mile",
};

const measurementSystemToTemperatureUnit: {
  [key in MeasurementSystem]: string;
} = {
  [MeasurementSystem.Metric]: "°C",
  [MeasurementSystem.Imperial]: "°F",
};

let measurementSystem: MeasurementSystem | null = null;

const LANGUAGE_CACHE_KEY = "LocaleProvider::language";
let language: Language | null =
  (localStorage.getItem(LANGUAGE_CACHE_KEY) as Language) || null;

class LocaleProvider {
  static getLocale() {
    if (language) {
      return languageToLocale[language];
    }
    const userLocale = navigator.languages
      ? navigator.languages[0]
      : navigator.language;
    return (
      Object.values(Locale).find((locale) => locale === userLocale) ||
      Object.values(Locale).find((locale) => userLocale.includes(locale)) ||
      Locale.EN
    );
  }

  static setLanguage(_language: Language) {
    language = _language;
    localStorage.setItem(LANGUAGE_CACHE_KEY, _language);
  }

  static getLanguage() {
    return language || Language.En;
  }

  static setMeasurementSystem(_measurementSystem: MeasurementSystem) {
    measurementSystem = _measurementSystem;
  }

  static getMeasurementSystem() {
    return (
      measurementSystem ||
      localeToMeasurementSystem[LocaleProvider.getLocale()] ||
      MeasurementSystem.Metric
    );
  }

  static getWeightUnit() {
    return measurementSystemToWeightUnit[LocaleProvider.getMeasurementSystem()];
  }

  static getDistanceUnit() {
    return measurementSystemToDistanceUnit[
      LocaleProvider.getMeasurementSystem()
    ];
  }

  static getMileageUnit() {
    return measurementSystemToMileageUnit[
      LocaleProvider.getMeasurementSystem()
    ];
  }

  static getTemperatureUnit() {
    return measurementSystemToTemperatureUnit[
      LocaleProvider.getMeasurementSystem()
    ];
  }

  static getTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";
  }

  static getCurrencyUnit() {
    // We only support USD for now
    return "USD";
  }

  static getCurrencySymbol() {
    // We only support USD for now
    return "$";
  }
}

export default LocaleProvider;
