import { Box, IconButton, Stack, TextField } from "@mui/material";
import { useState } from "react";
import {
  BusinessEntityType,
  GetOrderDetailsQuery,
  GoodProfile,
  NewOrderInput,
  ShipmentLocationType,
} from "../../../graphql/generated";
import BusinessEntitySelectContainer from "../../shipment/ShipmentForm/BusinessEntitySelect";
import { fallbackTimezone } from "../../../utils/labels/formatDateTime";
import LabeledAddButton from "../../common/LabeledAddButton";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { LoadingButton } from "@mui/lab";
import { v4 as uuid } from "uuid";
import ReceiverFormContainer from "../../shipment/ShipmentForm/ReceiverForm";
import { endOfDay, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { RemoveCircle } from "@mui/icons-material";

export type OrderFormProps = {
  initialOrder?: GetOrderDetailsQuery["orderById"];
  goodProfiles: Array<GoodProfile>;
  onSave: (order: NewOrderInput) => void;
  submitting: boolean;
  error: Error | null;
  hideCustomerSelect?: boolean;
};

const OrderForm = (props: OrderFormProps) => {
  const { t } = useTranslation(["orders"]);
  const [localOrder, setLocalOrder] = useState<Partial<NewOrderInput>>(
    props.initialOrder
      ? {
          customerId: props.initialOrder.customerId,
          orderNumber: props.initialOrder.orderNumber,
          status: props.initialOrder.status,
          locations: props.initialOrder.locations?.map((location) => ({
            ...location,
            addressTimezone: location.addressTimezone || fallbackTimezone,
            receivedGoods: location.receivedGoods || [],
            shippedGoods: [],
            shipper: null,
            receiver: location.receiver?._id || null,
          })),
        }
      : {}
  );

  const updateOrder = (updates: Partial<NewOrderInput>) => {
    setLocalOrder((prev) => ({ ...prev, ...updates }));
  };

  return (
    <Stack spacing={2}>
      <ErrorMessage error={props.error} />
      {props.hideCustomerSelect ? null : (
        <BusinessEntitySelectContainer
          businessEntityType={BusinessEntityType.Customer}
          onChange={(customerId) => {
            updateOrder({ customerId: customerId || undefined });
          }}
          value={localOrder.customerId || ""}
        />
      )}
      <TextField
        label={t("orderNumber", "Order Number")}
        value={localOrder.orderNumber || ""}
        onChange={(e) => updateOrder({ orderNumber: e.target.value })}
      />
      {localOrder.locations?.map((location) => {
        return (
          <Box sx={{ position: "relative" }}>
            {(localOrder?.locations || []).length > 1 && (
              <IconButton
                sx={{ position: "absolute", right: -30, top: -30 }}
                color="default"
                onClick={() => {
                  updateOrder({
                    locations: localOrder.locations?.filter(
                      (l) => l._id !== location._id
                    ),
                  });
                }}
              >
                <RemoveCircle />
              </IconButton>
            )}
            <ReceiverFormContainer
              receiver={{
                ...location,
                _id: location._id || uuid(),
                receivedGoods: location.receivedGoods || [],
                shippedGoods: location.shippedGoods || [],
              }}
              onChange={(location) => {
                updateOrder({
                  locations: localOrder.locations?.map((l) => {
                    if (l._id === location._id) {
                      return {
                        ...location,
                        shipper: null,
                        receiver: location.receiver || null,
                      };
                    }
                    return l;
                  }),
                });
              }}
              shippedGoods={props.goodProfiles.map((goodProfile) => ({
                _id: goodProfile._id,
                label: goodProfile.label,
                quantity: Infinity,
                goodProfileId: goodProfile._id,
                unit: goodProfile.unit,
                weight: goodProfile.weight,
              }))}
              receivedGoods={[]}
            />
          </Box>
        );
      })}

      <LabeledAddButton
        label={t("addReceiver", "Add Receiver")}
        onClick={() => {
          updateOrder({
            locations: [
              ...(localOrder.locations || []),
              {
                _id: uuid(),
                locationType: ShipmentLocationType.DropOff,
                location: {
                  latitude: 0,
                  longitude: 0,
                },
                addressLabel: "",
                name: "",
                addressTimezone: "",
                timeWindows: [
                  {
                    fromDate: startOfDay(new Date()),
                    toDate: endOfDay(new Date()),
                  },
                ],
                receivedGoods: [],
                shippedGoods: [],
                shipper: null,
                receiver: null,
              },
            ],
          });
        }}
      />

      <LoadingButton
        variant="contained"
        loading={props.submitting}
        onClick={() => {
          props.onSave(localOrder as NewOrderInput);
        }}
      >
        {props.initialOrder
          ? t("update", "Update Order")
          : t("create", "Create Order")}
      </LoadingButton>
    </Stack>
  );
};

export default OrderForm;
