import { CustomField } from "../../../graphql/generated";
import { CustomFieldDefinitionListItem } from "../CustomFieldsList/CustomFieldsList";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo";

export type CustomFieldsDisplayProps = {
  customFields?: CustomField[] | null;
  customFieldDefinitions: CustomFieldDefinitionListItem[];
};

const CustomFieldsDisplay = ({
  customFields,
  customFieldDefinitions,
}: CustomFieldsDisplayProps) => {
  if (!customFields?.length) {
    return null;
  }
  return (
    <InfoBlock title={"Custom Fields"}>
      {customFields?.map((customField) => {
        const customFieldDefinition = customFieldDefinitions.find(
          (definition) => definition.key === customField.key
        );
        return (
          <LabeledInfo
            key={customField.key}
            title={customFieldDefinition?.label || customField.key}
            value={customField.value}
          />
        );
      })}
    </InfoBlock>
  );
};

export default CustomFieldsDisplay;
