import { Box, Button, Chip, Stack, Tab, Tabs } from "@mui/material";
import { capitalize, last, omit } from "lodash";
import {
  GetTripListQuery,
  TripSortCriteria,
  Status,
} from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerLabel from "../../../utils/labels/trailerLabel";
import {
  EnumTableField,
  SortField,
  TableField,
} from "../../common/LynksTable/LynksTable";
import ShipmentLink from "../../common/ShipmentLink";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import TripLink from "../../common/TripLink";
import { ShipmentLocationTime } from "../../shipment/ShipmentSummary/ShipmentSummary";
import {
  shipmentLocationAddressLabel,
  shipmentLocationLabel,
} from "../../../utils/labels/shipmentLocationLabel";
import carrierLabel from "../../../utils/labels/carrierLabel";
import { useMemo, useState } from "react";
import RoleGuard from "../../account/Access/RoleGuard";
import { useTranslation } from "react-i18next";
import statusLabel from "../../../utils/labels/statusLabel";

type TripListItem = GetTripListQuery["trips"]["data"][0];

const statusField: EnumTableField<TripListItem, Status> = {
  value: (trip) => trip.status,
  valueLabel: statusLabel,
  label: "Status",
  type: "enum",
  colors: {
    ASSIGNED: "primary",
    ACTIVE: "primary",
    CANCELLED: "error",
    COMPLETE: "success",
    DELETED: "error",
    DELIVERED: "success",
    PENDING: "default",
    UNASSIGNED: "default",
    WAITING_FOR_APPROVAL: "default",
    WAITING_FOR_ASSIGNMENT: "default",
    PLANNED: "primary",
    IN_PROGRESS: "honeyDew",
  },
  values: Object.values(Status),
};

export enum TripStatusTab {
  ALL,
  UNASSIGNED,
  ASSIGNED,
  IN_PROGRESS,
  PAST,
  CANCELLED,
}

const statusTabToTripStatus: {
  [key in TripStatusTab]: Status | null;
} = {
  [TripStatusTab.ALL]: null,
  [TripStatusTab.ASSIGNED]: Status.Assigned,
  [TripStatusTab.UNASSIGNED]: Status.WaitingForAssignment,
  [TripStatusTab.IN_PROGRESS]: Status.InProgress,
  [TripStatusTab.PAST]: Status.Complete,
  [TripStatusTab.CANCELLED]: Status.Deleted,
};

export type TripsListProps = {
  query: QueryFn<TripListItem, "trips", TripSortCriteria>;
  excludeCarrierAssigned?: boolean;
  excludeDriverAssigned?: boolean;
};

export default function TripsList({
  query,
  excludeCarrierAssigned,
  excludeDriverAssigned,
}: TripsListProps) {
  const { t } = useTranslation([
    "trips",
    "business",
    "assets",
    "users",
    "orders",
    "common",
  ]);
  const [statusTab, setStatusTab] = useState<TripStatusTab>(TripStatusTab.ALL);

  const sortFields: SortField<TripSortCriteria>[] = useMemo(
    () => [
      {
        criteria: TripSortCriteria.StartDate,
        label: t("trips:startDate", "Start Date"),
      },
      {
        criteria: TripSortCriteria.FinishDate,
        label: t("trips:finishDate", "Finish Date"),
      },
      {
        criteria: TripSortCriteria.StartLocation,
        label: t("trips:startLocation", "Start Location"),
      },
      {
        criteria: TripSortCriteria.EndLocation,
        label: t("trips:endLocation", "End Location"),
      },
    ],
    [t]
  );

  const fields: TableField<GetTripListQuery["trips"]["data"][0], Status>[] =
    useMemo(
      () => [
        {
          value: (trip) => <TripLink trip={trip} />,
          label: capitalize(t("trips:tripNumber", "Trip Number")),
          type: "number",
          sortBy: "tripNumber",
        },
        {
          value: (trip) => (
            <>
              {trip.shipments.map((shipment) => (
                <Box key={shipment._id}>
                  <ShipmentLink shipment={shipment} />
                </Box>
              ))}
            </>
          ),
          label: capitalize(t("orders", "Orders")),
          type: "number",
          sortBy: "shipments[0].number",
        },
        statusField,
        {
          value: (trip) => {
            const firstShipmentLocation = omit(
              trip.shipmentLocations[0],
              "timeWindows"
            );
            if (!firstShipmentLocation) {
              return;
            }
            return (
              <ShipmentLocationTime
                variant="body1"
                hideEndTime
                shipmentLocation={firstShipmentLocation}
              />
            );
          },
          label: t("trips:startDate", "Start Date"),
          type: "datetime",
          sortBy: "firstPickupTime",
        },
        {
          value: (trip) => {
            const lastShipmentLocation = omit(
              last(trip.shipmentLocations),
              "timeWindows"
            );
            if (!lastShipmentLocation) {
              return;
            }
            return (
              <ShipmentLocationTime
                variant="body1"
                hideEndTime
                shipmentLocation={lastShipmentLocation}
              />
            );
          },
          label: t("trips:finishDate", "Finish Date"),
          type: "datetime",
          sortBy: "lastDropoffTime",
        },
        {
          value: (trip) => `${trip.shipments.length}`.padStart(2, "0"),
          label: t("ordersCount", "Orders count"),
          type: "number",
          sortBy: "shipments.length",
        },
        {
          value: (trip) => shipmentLocationLabel(trip.shipmentLocations[0]),
          subtitle: (trip) =>
            shipmentLocationAddressLabel(trip.shipmentLocations[0]),
          label: t("trips:startLocation", "Start Location"),
          type: "string",
          sortBy: "shipmentLocations[0].shipper.name",
        },
        {
          value: (trip) => shipmentLocationLabel(last(trip.shipmentLocations)),
          subtitle: (trip) =>
            shipmentLocationAddressLabel(last(trip.shipmentLocations)),
          label: t("trips:endLocation", "End Location"),
          type: "string",
          sortBy: "shipmentLocations[-1].receiver.name",
        },
        {
          value: (trip) =>
            `${
              trip.driver
                ? driverLabel(trip.driver)
                : trip.carrier
                ? carrierLabel(trip.carrier)
                : "N/A"
            } - ${tractorLabel(trip.tractor)} - ${trailerLabel(trip.trailer)}`,
          label: () =>
            window.location.pathname.endsWith("carrier-trips")
              ? `${capitalize(
                  t("business:carrier.one", "Carrier")
                )} / ${capitalize(
                  t("assets:tractor.one", "Tractor")
                )} / ${capitalize(t("assets:trailer.one", "Trailer"))}`
              : `${capitalize(t("users:driver.one", "Driver"))} / ${capitalize(
                  t("assets:tractor.one", "Tractor")
                )} / ${capitalize(t("assets:trailer.one", "Trailer"))}`,
          type: "string",
          sortBy: "driver.firstname",
        },
        {
          value: (trip) => (
            <Stack direction="row" spacing={1}>
              {trip.tags?.map((tag) => (
                <Chip label={tag} />
              ))}
            </Stack>
          ),
          sortBy: "tags",
          label: t("common:tags"),
          type: "string",
        },
      ],
      [t]
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tabs
          value={statusTab}
          onChange={(event, value) => {
            setStatusTab(value);
          }}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab
            label={t("orders:filter.all", "All")}
            value={TripStatusTab.ALL}
          />
          <Tab
            label={t("orders:filter.unassigned", "Unassigned")}
            value={TripStatusTab.UNASSIGNED}
          />
          <Tab
            label={t("orders:filter.assigned", "Assigned")}
            value={TripStatusTab.ASSIGNED}
          />
          <Tab
            label={t("orders:filter.inProgress", "In Progress")}
            value={TripStatusTab.IN_PROGRESS}
          />
          <Tab
            label={t("orders:filter.complete", "Completed")}
            value={TripStatusTab.PAST}
          />
          <Tab
            label={t("orders:filter.cancelled", "Cancelled")}
            value={TripStatusTab.CANCELLED}
          />
        </Tabs>
      </Box>
      <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
        <Button
          href="/trips/new"
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            alignSelf: "end",
            mb: 2,
          }}
        >
          {t("trips:new", "New Trip")}
        </Button>
      </RoleGuard>
      <Box
        sx={{
          height: "calc(100% - 112px)",
        }}
      >
        <SmartLynksTable
          query={query}
          dataKey="trips"
          fields={fields}
          sortFields={sortFields}
          additionalQueryVariables={{
            excludeCarrierAssigned,
            excludeDriverAssigned,
            status: statusTabToTripStatus[statusTab],
          }}
          detailsUrlPrefix={
            excludeDriverAssigned ? "/carrier-trips/details" : "/trips/details"
          }
        />
      </Box>
    </div>
  );
}
