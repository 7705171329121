import { useGetTrailerListQuery } from "../../../../../graphql/generated";
import { ConditionValueSelectorComponentProps } from "../../ConditionForm";
import TrailerInput from "./TrailerInput";

type TrailerInputContainerProps = ConditionValueSelectorComponentProps;

const TrailerInputContainer = ({ ...props }: TrailerInputContainerProps) => {
  const query = useGetTrailerListQuery();
  return <TrailerInput {...props} trailers={query.data?.trailers.data || []} />;
};

export default TrailerInputContainer;
