import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import {
  GetShipmentListQuery,
  ShipmentLocationType,
  SplitShipmentLocationInput,
} from "../../../graphql/generated";
import {
  shipmentLocationLabel,
  shipmentLocationAddressLabel,
} from "../../../utils/labels/shipmentLocationLabel";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  AddCircle,
  CircleOutlined,
  Delete,
  DragIndicator,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import AddressInput from "../../asset-management/AddressInput";
import { arrayMoveImmutable } from "array-move";
import { LoadingButton } from "@mui/lab";
import { without } from "lodash";

type LoadSplittingShipment = GetShipmentListQuery["shipments"]["data"][0];

export type LoadSplittingModalProps = {
  open: boolean;
  splitting: boolean;
  onClose: () => void;
  onSubmit: (
    shipmentId: string,
    locations: SplitShipmentLocationInput[]
  ) => void;
  shipment: LoadSplittingShipment;
};

const LoadSplittingModal = ({
  shipment,
  splitting,
  open,
  onSubmit,
  onClose,
}: LoadSplittingModalProps) => {
  const [locations, setLocations] = useState<
    SplitShipmentLocationInput[] | null
  >(
    shipment.route?.locations?.map((location) => ({
      location: location.location,
      locationType: location.locationType,
      _id: location._id,
      addressLabel: shipmentLocationAddressLabel(location),
      name: shipmentLocationLabel(location),
    })) || null
  );

  useEffect(() => {
    setLocations(
      shipment.route?.locations?.map((location) => ({
        location: location.location,
        locationType: location.locationType,
        _id: location._id,
        addressLabel: shipmentLocationAddressLabel(location),
        name: shipmentLocationLabel(location),
      })) || null
    );
  }, [shipment]);

  if (!locations) {
    return null;
  }
  return (
    <Dialog fullWidth open={open} onClose={() => onClose()}>
      <DialogTitle>Split load</DialogTitle>
      <DialogContent dividers>
        <SortableRouteLocations
          locations={locations}
          onSortEnd={({ oldIndex, newIndex }) => {
            setLocations(arrayMoveImmutable(locations, oldIndex, newIndex));
          }}
          onChange={(newLocations) => {
            setLocations(newLocations);
          }}
          helperClass="sortableZindexHelper"
          shouldCancelStart={(event) => {
            if (event.target instanceof SVGPathElement) {
              return (
                event.target.parentElement?.parentElement instanceof
                HTMLButtonElement
              );
            }
            return ["button", "input", "textarea", "select", "option"].includes(
              (event.target as HTMLElement).tagName.toLowerCase()
            );
          }}
        />
        <Button
          onClick={() => {
            setLocations(
              locations.concat({
                location: {
                  latitude: 0,
                  longitude: 0,
                },
                locationType: ShipmentLocationType.DropForHook,
                addressLabel: "",
                name: "",
              })
            );
          }}
          startIcon={<AddCircle />}
          sx={{
            mt: 3,
          }}
        >
          Add a splitting point
        </Button>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus
          color="inherit"
          variant="contained"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={() => {
            onSubmit(shipment._id, locations);
          }}
          id="vaidate-split-button"
          loading={splitting}
        >
          Split Load
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const SortableRouteLocations = SortableContainer(
  ({
    locations,
    onChange,
  }: {
    locations: SplitShipmentLocationInput[];
    onChange: (locations: SplitShipmentLocationInput[]) => void;
  }) => {
    return (
      <Stack spacing={2}>
        {locations.map((location, index) => (
          <SortableRouteLocationItem
            disabled={
              location.locationType !== ShipmentLocationType.DropForHook
            }
            location={location}
            key={location._id}
            index={index}
            onChange={(changedLocation) =>
              onChange(
                locations.map((loc) =>
                  loc === location ? changedLocation : loc
                )
              )
            }
            onDelete={() => {
              onChange(without(locations, location));
            }}
          />
        ))}
      </Stack>
    );
  }
);

const SortableRouteLocationItem = SortableElement(
  ({
    location,
    onChange,
    onDelete,
  }: {
    location: SplitShipmentLocationInput;
    onChange: (location: SplitShipmentLocationInput) => void;
    onDelete: () => void;
  }) => {
    return (
      <Box display="flex" alignItems={"center"}>
        <SortableRouteLocationItemDragHandle location={location} />
        {location.locationType === ShipmentLocationType.DropForHook ? (
          <>
            <AddressInput
              value={{
                label: location.addressLabel || "",
                coordinates: location.location,
              }}
              onChange={(address) => {
                onChange({
                  ...location,
                  addressLabel: address?.label,
                  name: address?.label,
                  location: address?.coordinates || location.location,
                });
              }}
              disableDetails
            />
            <IconButton
              onClick={() => {
                onDelete();
              }}
            >
              <Delete />
            </IconButton>
          </>
        ) : (
          <TextField
            value={shipmentLocationAddressLabel(location)}
            disabled
            sx={{
              flex: 1,
              mr: 5,
            }}
          />
        )}
      </Box>
    );
  }
);

const SortableRouteLocationItemDragHandle = SortableHandle(
  ({ location }: { location: SplitShipmentLocationInput }) => (
    <Box
      className="drag-handle"
      sx={{
        cursor: "move",
        mr: 2,
      }}
    >
      {location.locationType === ShipmentLocationType.DropForHook ? (
        <DragIndicator color="primary" />
      ) : (
        <CircleOutlined color="disabled" />
      )}
    </Box>
  )
);

export default LoadSplittingModal;
