import {
  DeleteOutlineOutlined,
  LocationOn,
  ModeEditOutlineOutlined,
  Tag,
  Abc,
  LiveHelp,
  MonitorWeight,
  Height,
  Straighten,
  WidthFull,
  Thermostat,
  LocalShipping,
  LinkOutlined,
  Style,
  GroupWork,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { MarkerF } from "@react-google-maps/api";
import { useState } from "react";
import {
  GetTrailerDetailsQuery,
  TripAssetTypes,
} from "../../../graphql/generated";
import toLatLng from "../../../utils/geo/toLaLng";
import formatWeight from "../../../utils/labels/formatWeight";
import formatDistance from "../../../utils/labels/formatDistance";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo";
import Map from "../../common/Map/Map";
import formatTemperature from "../../../utils/labels/formatTemperature";
import { hasValue } from "../../../utils/form/hasValue";

import AssetLinkingFormModal from "../AssetLinkingFormModal";
import trailerTypeLabel from "../../../utils/labels/trailerTypeLabel";
import enumLabel from "../../../utils/labels/enumLabel";
import FeatureGuard, { Feature } from "../../account/Access/FeatureGuard";
import { useTranslation } from "react-i18next";
import ChipTagsList from "../../common/ChipTagsList/ChipTagsList";
import MaintenanceTasksListContainer from "../../maintenance/MaintenanceTasksList";
import GroupsDisplayContainer from "../GroupsDisplay";
import CustomFieldsDisplayContainer from "../../custom-field-definitions/CustomFieldsDisplay";
type TrailerDetailsData = GetTrailerDetailsQuery["trailerById"];

type TrailerDetailsProps = {
  trailer: TrailerDetailsData;
  onEdit: () => void;
  onDelete: () => void;
};

function TrailerDetails({ trailer, onEdit, onDelete }: TrailerDetailsProps) {
  const { t } = useTranslation(["assets", "common"]);
  const [isAddressMapOpen, setIsAddressMapOpen] = useState(false);
  const [isLinkingModalOpen, setIsLinkingModalOpen] = useState(false);

  return (
    <Box>
      <Stack direction="row" flexDirection="row-reverse" spacing={2}>
        <IconButton onClick={onDelete} color="error">
          <DeleteOutlineOutlined />
        </IconButton>
        <IconButton onClick={onEdit} color="primary">
          <ModeEditOutlineOutlined />
        </IconButton>
        <Tooltip title="Link to tractor/trailer">
          <IconButton
            onClick={() => {
              setIsLinkingModalOpen(true);
            }}
            color="primary"
          >
            <LinkOutlined />
          </IconButton>
        </Tooltip>
      </Stack>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <InfoBlock title={t("assets:trailer.details", "Trailer Details")}>
            <LabeledInfo
              title={t("assets:code", "Asset Code")}
              value={trailer.serialNumber}
              icon={<Abc />}
            />
            <LabeledInfo
              title={t("assets:licenseNumber", "License Number")}
              value={trailer.licenseNumber?.toString()}
              icon={<Tag />}
            />
            <LabeledInfo
              title={t("assets:trailer.type", "Type")}
              value={trailerTypeLabel(trailer.type)}
              icon={<LocalShipping />}
            />
            <LabeledInfo
              title={t("assets:status", "Status")}
              value={trailer.status}
              icon={<LiveHelp />}
            />

            <LabeledInfo
              title={t("common:tags")}
              value={<ChipTagsList value={trailer.tags || []} />}
              icon={<Style />}
            />

            <LabeledInfo
              title={t("common:groups")}
              value={<GroupsDisplayContainer groupIds={trailer.groupIds} />}
              icon={<GroupWork />}
            />
          </InfoBlock>
        </Grid>

        <Grid item sm={6}>
          <InfoBlock title={t("assets:domicile", "Domicile")}>
            <LabeledInfo
              title={t("assets:address", "Address")}
              value={trailer.domicile?.label}
              icon={<LocationOn />}
              onClick={() => {
                if (trailer.domicile) {
                  setIsAddressMapOpen(true);
                }
              }}
            />
          </InfoBlock>
        </Grid>

        <Grid item sm={6}>
          <InfoBlock title={t("assets:trailer.capacity", "Trailer Capacity")}>
            <LabeledInfo
              title={t("assets:trailer.width", "Width")}
              value={
                hasValue(trailer.width) ? formatDistance(trailer.width) : "N/A"
              }
              icon={<WidthFull />}
            />
            <LabeledInfo
              title={t("assets:trailer.height", "Height")}
              value={
                hasValue(trailer.height)
                  ? formatDistance(trailer.height)
                  : "N/A"
              }
              icon={<Height />}
            />
            <LabeledInfo
              title={t("assets:trailer.length", "Length")}
              value={
                hasValue(trailer.length)
                  ? formatDistance(trailer.length)
                  : "N/A"
              }
              icon={<Straighten />}
            />
            <LabeledInfo
              title={t("assets:trailer.numberOfAxles", "Number of Axles")}
              value={
                hasValue(trailer.numberOfAxles)
                  ? trailer.numberOfAxles?.toString()
                  : "N/A"
              }
              icon={<Straighten />}
            />
            <LabeledInfo
              title={t("assets:trailer.maxWeight", "Max Weight")}
              value={
                hasValue(trailer.maxWeight)
                  ? formatWeight(Number(trailer.maxWeight))
                  : "N/A"
              }
              icon={<MonitorWeight />}
            />
            <LabeledInfo
              title={t("assets:trailer.minTemperature", "Min Temperature")}
              value={
                hasValue(trailer.minTemperature)
                  ? formatTemperature(trailer.minTemperature)
                  : "N/A"
              }
              icon={<Thermostat />}
            />
            <LabeledInfo
              title={t("assets:trailer.maxTemperature", "Max Temperature")}
              value={
                hasValue(trailer.maxTemperature)
                  ? formatTemperature(trailer.maxTemperature)
                  : "N/A"
              }
              icon={<Thermostat />}
            />
          </InfoBlock>
        </Grid>
        <FeatureGuard feature={Feature.CommodityManagement}>
          <Grid item sm={12}>
            <InfoBlock title={t("assets:trailer.compartments", "Compartments")}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t("assets:trailer.compartmentId", "Compartment ID")}
                    </TableCell>
                    <TableCell>
                      {t("assets:trailer.capacity", "Capacity")}
                    </TableCell>
                    <TableCell>{t("assets:commodity.unit", "Unit")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trailer.compartments?.map((storageFacility) => (
                    <TableRow>
                      <TableCell>{storageFacility.identifier}</TableCell>
                      <TableCell>{storageFacility.capacity}</TableCell>
                      <TableCell>{enumLabel(storageFacility.unit)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </InfoBlock>
          </Grid>
        </FeatureGuard>

        <Grid item xs={12}>
          <CustomFieldsDisplayContainer customFields={trailer.customFields} />
        </Grid>

        <Grid item xs={12}>
          <InfoBlock title="Maintenance Tasks">
            <MaintenanceTasksListContainer
              assetType={TripAssetTypes.Trailer}
              assetId={trailer._id}
            />
          </InfoBlock>
        </Grid>
      </Grid>

      {trailer.domicile && (
        <Dialog
          open={isAddressMapOpen}
          onClose={() => setIsAddressMapOpen(false)}
        >
          <DialogTitle>{trailer.domicile?.label}</DialogTitle>

          <DialogContent>
            <Map center={toLatLng(trailer?.domicile?.coordinates)} zoom={13}>
              <MarkerF position={toLatLng(trailer?.domicile?.coordinates)} />
            </Map>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setIsAddressMapOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      <AssetLinkingFormModal
        isOpen={isLinkingModalOpen}
        onClose={() => {
          setIsLinkingModalOpen(false);
        }}
        selectedTrailerId={trailer._id}
      />
    </Box>
  );
}

export default TrailerDetails;
