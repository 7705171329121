import {
  Alert,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { GetCustomerOrderListQuery, Status } from "../../../graphql/generated";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import TripTimeline from "../../trip-planning/TripTimeline/TripTimeline";
import { orderStatusColors } from "../../order/OrdersList/OrdersList";
import enumLabel from "../../../utils/labels/enumLabel";
import { formatMileage } from "../../../utils/labels/formatDistance";
import ShipmentTrackerContainer from "../../tracking/ShipmentTracker";
import { differenceInSeconds } from "date-fns";

type CustomerCustomerOrderListItem =
  GetCustomerOrderListQuery["customerOrders"]["data"][0];

type CustomerOrdersListProps = {
  query: QueryFn<
    CustomerCustomerOrderListItem,
    "customerOrders",
    unknown,
    {
      status: Status | null;
    }
  >;
  onRefetch?: () => void;
  readonly?: boolean;
  disableSearch?: boolean;
  disablePagination?: boolean;
  disableStatusTab?: boolean;
};

export enum CustomerOrderStatusTab {
  ALL,
  UNASSIGNED,
  ASSIGNED,
  IN_PROGRESS,
  PAST,
  CANCELLED,
}

const statusTabToCustomerOrderStatus: {
  [key in CustomerOrderStatusTab]: Status | null;
} = {
  [CustomerOrderStatusTab.ALL]: null,
  [CustomerOrderStatusTab.ASSIGNED]: Status.Assigned,
  [CustomerOrderStatusTab.UNASSIGNED]: Status.WaitingForAssignment,
  [CustomerOrderStatusTab.IN_PROGRESS]: Status.InProgress,
  [CustomerOrderStatusTab.PAST]: Status.Complete,
  [CustomerOrderStatusTab.CANCELLED]: Status.Deleted,
};

export default function CustomerOrdersList({ query }: CustomerOrdersListProps) {
  const [statusTab] = useState<CustomerOrderStatusTab>(
    CustomerOrderStatusTab.ALL
  );

  const [selectedOrder, setSelectedOrder] =
    useState<CustomerCustomerOrderListItem | null>(null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {/* {disableStatusTab ? null : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={statusTab}
            onChange={(event, value) => {
              setStatusTab(value);
            }}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="All" value={CustomerOrderStatusTab.ALL} />
            <Tab label="Unassigned" value={CustomerOrderStatusTab.UNASSIGNED} />
            <Tab label="Assigned" value={CustomerOrderStatusTab.ASSIGNED} />
            <Tab
              label="In Progress"
              value={CustomerOrderStatusTab.IN_PROGRESS}
            />
            <Tab label="Completed" value={CustomerOrderStatusTab.PAST} />
            <Tab label="Cancelled" value={CustomerOrderStatusTab.CANCELLED} />
          </Tabs>
        </Box>
      )} */}

      <Stack
        sx={{
          alignSelf: "end",
          mb: 2,
          mt: 2,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <Button
          href="/customer-portal/orders/new"
          variant="contained"
          color="secondary"
          size="large"
          id="new-customer-order-button"
        >
          New Order
        </Button>
      </Stack>
      <Grid
        sx={{
          px: 2,
          height: "calc(100% - 75px)",
        }}
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={selectedOrder ? 6 : 12}
          sx={{ height: "100%", overflow: "auto" }}
        >
          <SmartLynksTable
            query={query}
            additionalQueryVariables={{
              status: statusTabToCustomerOrderStatus[statusTab],
            }}
            renderRow={(order) => (
              <Card
                sx={(theme) => ({
                  mb: 1,
                  mt: 1,
                  cursor: "pointer",
                  backgroundColor:
                    selectedOrder?._id === order._id
                      ? theme.palette.action.selected
                      : "",
                })}
                onClick={() => {
                  if (selectedOrder?._id === order._id) {
                    setSelectedOrder(null);
                    return;
                  }
                  setSelectedOrder(order);
                }}
              >
                <CardContent>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6">
                      Order# {order.orderNumber}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={3}>
                      {order.shipment?.route
                        ? formatMileage(order.shipment.route.routeDistance)
                        : null}
                      <Chip
                        color={orderStatusColors[order.status]}
                        label={enumLabel(order.status)}
                        sx={{
                          ml: 3,
                        }}
                      />
                    </Stack>
                  </Stack>
                  {order.shipment ? (
                    <TripTimeline
                      horizontal
                      trip={{
                        _id: order.shipment._id,
                        shipmentLocations: order.shipment.shipmentLocations.map(
                          (location) => ({
                            ...location,
                            arrivalTime: location.timeWindows[0]?.fromDate,
                            serviceDuration: location.timeWindows[0]
                              ? differenceInSeconds(
                                  new Date(location.timeWindows[0]?.toDate),
                                  new Date(location.timeWindows[0]?.fromDate)
                                )
                              : 0,
                            setupDuration: 0,
                            waitingDuration: 0,
                          })
                        ),
                        polyline: "",
                        shipments: [order.shipment],
                        status: order.shipment.status,
                      }}
                      onShipmentEventLog={() => {}}
                    />
                  ) : (
                    <TripTimeline
                      horizontal
                      trip={{
                        _id: order._id,
                        shipmentLocations: order.locations.map((location) => ({
                          ...location,
                          arrivalTime: location.timeWindows[0]?.fromDate,
                          serviceDuration: location.timeWindows[0]
                            ? differenceInSeconds(
                                new Date(location.timeWindows[0]?.toDate),
                                new Date(location.timeWindows[0]?.fromDate)
                              )
                            : 0,
                          setupDuration: 0,
                          waitingDuration: 0,
                        })),
                        polyline: "",
                        shipments: [],
                        status: order.status,
                      }}
                      onShipmentEventLog={() => {}}
                    />
                  )}
                </CardContent>
              </Card>
            )}
            dataKey="customerOrders"
            id="customerOrders-table"
          />
        </Grid>
        {selectedOrder ? (
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              height: "100%",
            }}
          >
            {selectedOrder?.shipment?.tripId ? (
              <ShipmentTrackerContainer
                shipmentId={selectedOrder.shipment?._id}
                mapOnly
              />
            ) : (
              <Alert severity="info">Order not yet assigned</Alert>
            )}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
