import { uniqBy } from "lodash";
import {
  BusinessEntityType,
  useAddBusinessEntityMutation,
  useGetBrokersQuery,
  useGetCustomersQuery,
  useGetDomicilesQuery,
  useGetPayToProfilesQuery,
  useGetReceiversQuery,
  useGetShippersQuery,
  useGetSuppliersQuery,
} from "../../../../graphql/generated";
import useDialog from "../../../../utils/hooks/useDialog";
import BusinessEntitySelect, {
  BusinessEntitySelectProps,
} from "./BusinessEntitySelect";
import { useTranslation } from "react-i18next";

type BusinessEntitySelectContainerProps = Omit<
  BusinessEntitySelectProps,
  "businessEntities" | "onBusinessEntitiesRefetch" | "createBusinessEntity"
> & {
  filterBusinessEntityIds?: string[];
};

const BusinessEntitySelectContainer = (
  props: BusinessEntitySelectContainerProps
) => {
  const { t } = useTranslation(["orders", "business", "common"]);
  const shippersQuery = useGetShippersQuery();
  const receiversQuery = useGetReceiversQuery();
  const customersQuery = useGetCustomersQuery();
  const brokersQuery = useGetBrokersQuery();
  const payToProfilesQuery = useGetPayToProfilesQuery();
  const suppliersQuery = useGetSuppliersQuery();
  const domicilesQuery = useGetDomicilesQuery();
  const addBusinessEntityMutation = useAddBusinessEntityMutation();
  const { showDialog } = useDialog();

  const shippers = shippersQuery.data?.shippers.data || [];
  const receivers = receiversQuery.data?.receivers.data || [];
  const customers = customersQuery.data?.customers.data || [];
  const brokers = brokersQuery.data?.brokers.data || [];
  const payToProfiles = payToProfilesQuery.data?.businessEntities.data || [];
  const suppliers = suppliersQuery.data?.businessEntities.data || [];
  const domiciles = domicilesQuery.data?.businessEntities.data || [];

  return (
    <BusinessEntitySelect
      {...props}
      businessEntities={(props.businessEntityType === BusinessEntityType.Shipper
        ? shippers
        : props.businessEntityType === BusinessEntityType.Receiver
        ? receivers
        : props.businessEntityType === BusinessEntityType.Customer
        ? uniqBy([...customers, ...brokers], "_id")
        : props.businessEntityType === BusinessEntityType.Broker
        ? brokers
        : props.businessEntityType === BusinessEntityType.PayToProfile
        ? payToProfiles
        : props.businessEntityType === BusinessEntityType.Supplier
        ? suppliers
        : props.businessEntityType === BusinessEntityType.Domicile
        ? domiciles
        : []
      ).filter((be) => {
        if (props.filterBusinessEntityIds?.length) {
          return props.filterBusinessEntityIds.includes(be._id);
        }
        return true;
      })}
      onBusinessEntitiesRefetch={async () => {
        await Promise.all([
          shippersQuery.refetch(),
          receiversQuery.refetch(),
          customersQuery.refetch(),
          brokersQuery.refetch(),
          payToProfilesQuery.refetch(),
          suppliersQuery.refetch(),
          domicilesQuery.refetch(),
        ]);
      }}
      createBusinessEntity={async (newBusinessEntityData) => {
        try {
          const result = await addBusinessEntityMutation.mutateAsync({
            newBusinessEntityData,
          });
          return result.addBusinessEntity;
        } catch (error) {
          showDialog({
            title: t("common:error.title", "Error"),
            description:
              t(
                "business:createLocationError",
                "An error occurred while creating the location."
              ) + (error as Error)?.message ||
              t("common:error.unknownError", "Unknown error"),
          });
          return null;
        }
      }}
    />
  );
};

export default BusinessEntitySelectContainer;
