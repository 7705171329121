import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfMonth, isValid, startOfMonth } from "date-fns";
import { useState } from "react";
import { Driver, Period, ReportType } from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import reportTypeLabel from "../../../utils/labels/reportTypeLabel";
import { useTranslation } from "react-i18next";

export type ReportFilter = {
  driver?: string | null;
};

type ReportGeneratorProps = {
  onGenerateAndDownloadReport: (
    reportType: ReportType,
    period: Period,
    filter: ReportFilter
  ) => void;
  generating: boolean;
  drivers: Pick<Driver, "_id" | "firstname" | "lastname">[];
};

const ReportGenerator = ({
  generating,
  onGenerateAndDownloadReport,
  drivers,
}: ReportGeneratorProps) => {
  const { t } = useTranslation("finance");
  const [reportType, setReportType] = useState<ReportType | null>(
    ReportType.RevenuePerDay
  );
  const [periodStart, setPeriodStart] = useState(startOfMonth(new Date()));
  const [periodEnd, setPeriodEnd] = useState(endOfMonth(new Date()));
  const [filter, setFilter] = useState<ReportFilter>({});
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t(
          "reportSelectDescription",
          "Select a report you want to generate and download"
        )}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={2}>
          <Select
            label="Report type"
            size="small"
            value={reportType}
            onChange={(event) => {
              setReportType(event.target.value as ReportType);
              setFilter({});
            }}
          >
            {Object.values(ReportType).map((target) => (
              <MenuItem key={target} value={target}>
                <ListItemText primary={reportTypeLabel(target)} />
              </MenuItem>
            ))}
          </Select>
          {reportType === ReportType.DriverMileagePerTrip ? (
            <Autocomplete
              disablePortal
              fullWidth
              options={drivers}
              getOptionLabel={driverLabel}
              onChange={(event, driver) => {
                setFilter({
                  driver: driver?._id,
                });
              }}
              value={
                drivers.find((driver) => driver._id === filter.driver) || null
              }
              size="small"
              renderInput={(inputProps) => (
                <TextField {...inputProps} label="Driver" />
              )}
            />
          ) : null}
        </Stack>
        <DatePicker
          value={periodStart}
          onChange={(value) => {
            if (!value) {
              return;
            }
            if (!isValid(value)) {
              return;
            }
            setPeriodStart(value);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          value={periodEnd}
          onChange={(value) => {
            if (!value) {
              return;
            }
            if (!isValid(value)) {
              return;
            }
            setPeriodEnd(value);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <Stack>
          <LoadingButton
            variant="outlined"
            loading={generating}
            onClick={() => {
              if (!reportType) {
                return;
              }
              onGenerateAndDownloadReport(
                reportType,
                {
                  start: periodStart,
                  end: periodEnd,
                },
                filter
              );
            }}
            disabled={!reportType || generating}
          >
            {t("generateAndDownload", "Generate and download")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ReportGenerator;
