import { Autocomplete, TextField } from "@mui/material";
import { isString } from "lodash";
import { CustomFieldInputComponent } from "../CustomFieldsForm";

const SelectCustomFieldInput: CustomFieldInputComponent = ({
  value,
  customFieldDefinition,
  onChange,
}) => {
  return (
    <Autocomplete
      options={
        customFieldDefinition.selectOptions?.map((option) => option.key) || []
      }
      getOptionLabel={(optionKey) =>
        customFieldDefinition.selectOptions?.find(
          (selectOption) => selectOption.key === optionKey
        )?.label || ""
      }
      onChange={(event, value) => {
        onChange(value);
      }}
      value={isString(value) ? value : null}
      size="medium"
      fullWidth
      renderInput={(inputProps) => (
        <TextField label={customFieldDefinition.label} {...inputProps} />
      )}
    />
  );
};

export default SelectCustomFieldInput;
