import { useCallback } from "react";
import { useParams } from "react-router-dom";
import GoodProfileDetails from "../../../components/commodity-management/GoodProfileDetails";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { GoodProfile } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";

function ViewGoodProfile() {
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (goodProfile: Pick<GoodProfile, "code">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle label="Commodity" suffix={goodProfile?.code || ""} />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions]
  );
  if (!id) {
    return null;
  }
  return <GoodProfileDetails onLoad={onLoad} goodProfileId={id} />;
}

export default ViewGoodProfile;
