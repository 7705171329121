import { useCallback } from "react";
import { useParams } from "react-router-dom";
import TrailerDetails from "../../../components/asset-management/TrailerDetails";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { Trailer } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";

function ViewTrailer() {
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const onLoad = useCallback(
    (trailer: Pick<Trailer, "serialNumber">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle label="Trailer" suffix={trailer?.serialNumber || ""} />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions]
  );
  if (!id) {
    return null;
  }
  return <TrailerDetails onLoad={onLoad} trailerId={id} />;
}

export default ViewTrailer;
