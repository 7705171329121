import { useGetShipmentListQuery } from "../../../graphql/generated";
import ShipmentsList from "./ShipmentsList";

export default function ShipmentsListContainer() {
  let refetch = () => {};
  // I need a handle on refetch so I can call it
  // a new settlement has been generated
  const useGetShipmentListQueryCustom: typeof useGetShipmentListQuery = (
    variables,
    options
  ) => {
    const query = useGetShipmentListQuery(variables, {
      ...options,
    });
    refetch = query.refetch;
    return query;
  };
  useGetShipmentListQueryCustom.fetcher = useGetShipmentListQuery.fetcher;
  useGetShipmentListQueryCustom.getKey = useGetShipmentListQuery.getKey;
  return (
    <ShipmentsList
      query={useGetShipmentListQueryCustom}
      onRefetch={() => {
        refetch();
      }}
    />
  );
}
