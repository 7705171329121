import { Autocomplete, TextField } from "@mui/material";
import { castArray } from "lodash";
import { CustomFieldInputComponent } from "../CustomFieldsForm";

const MultiSelectCustomFieldInput: CustomFieldInputComponent = ({
  value,
  customFieldDefinition,
  onChange,
}) => {
  return (
    <Autocomplete
      options={
        customFieldDefinition.selectOptions?.map((option) => option.key) || []
      }
      getOptionLabel={(optionKey) =>
        customFieldDefinition.selectOptions?.find(
          (selectOption) => selectOption.key === optionKey
        )?.label || ""
      }
      onChange={(event, value) => {
        onChange(value.map(String));
      }}
      multiple
      value={value ? castArray(value) : []}
      size="medium"
      fullWidth
      renderInput={(inputProps) => (
        <TextField label={customFieldDefinition.label} {...inputProps} />
      )}
    />
  );
};

export default MultiSelectCustomFieldInput;
