import { Chip, Stack } from "@mui/material";

type TagsListProps = {
  value: string[];
};

const ChipTagsList = ({ value }: TagsListProps) => {
  return (
    <Stack direction="row" spacing={1} flexWrap="wrap">
      {value.map((tag) => (
        <Chip label={tag} key={tag} />
      ))}
    </Stack>
  );
};

export default ChipTagsList;
