import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { ValidationResult } from "joi";
import {
  NewMaintenanceTaskInput,
  GetMaintenanceTaskDetailsQuery,
  TripAssetTypes,
  Scalars,
  MaintenanceTaskRecurrenceFrequency,
} from "../../../graphql/generated";
import { isArray, mergeWith, omit, omitBy } from "lodash";
import maintenanceTaskSchema from "./maintenanceTaskSchema";
import useConfirmBeforeLeave from "../../../utils/hooks/useConfirmBeforeLeave";
import { FormDivider } from "../../asset-management/BusinessEntityForm/BusinessEntityForm";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "@mui/x-date-pickers";
import EnumSelect from "../../common/EnumSelect";
import NumberTextField from "../../common/NumberTextField";

type MaintenanceTask = GetMaintenanceTaskDetailsQuery["maintenanceTaskById"];

export type MaintenanceTaskFormProps = {
  assetType: TripAssetTypes;
  assetId: Scalars["ObjectId"];
  initialMaintenanceTask?: MaintenanceTask;
  saving: boolean;
  onSave: (maintenanceTask: NewMaintenanceTaskInput) => void;
};
type PartialMaintenanceTask = Partial<MaintenanceTask>;
type DeepPartialMaintenanceTask = {
  [key in keyof PartialMaintenanceTask]: Partial<PartialMaintenanceTask[key]>;
};

const MaintenanceTaskForm = ({
  initialMaintenanceTask,
  assetType,
  assetId,
  saving,
  onSave,
}: MaintenanceTaskFormProps) => {
  const { t } = useTranslation(["users", "common"]);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<MaintenanceTask> | null>(null);
  const [localMaintenanceTask, setLocalMaintenanceTask] = useState<
    Partial<NewMaintenanceTaskInput>
  >(
    initialMaintenanceTask || {
      assetType,
      assetId,
    }
  );

  const getFieldError = (field: string, partialPathMatch = false) =>
    validationResult?.error?.details.find((error) =>
      partialPathMatch
        ? error.path.join(".").startsWith(field)
        : error.path.join(".") === field
    )?.message;

  const onChange = useCallback((changes: DeepPartialMaintenanceTask) => {
    setLocalMaintenanceTask((localMaintenanceTask) =>
      mergeWith({}, localMaintenanceTask, changes, (objValue, srcValue) => {
        if (isArray(srcValue)) {
          return srcValue;
        }
      })
    );
  }, []);

  const validate = () => {
    const validationResult = maintenanceTaskSchema.validate(
      omit(localMaintenanceTask, "_id"),
      {
        abortEarly: false,
      }
    );
    setValidationResult(validationResult);
    return !validationResult.error;
  };

  useEffect(() => {
    if (validationResult) {
      validate();
    }
    // We don't want to run everytime validationResult changes
    // otherwise we ill have an infinite update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localMaintenanceTask]);

  const { cancelConfirm } = useConfirmBeforeLeave(localMaintenanceTask);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <FormDivider variant="fullWidth" text="Maintenance Task Details" />
          <ErrorMessage message={validationResult?.error?.message} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <TextField
                  label={t("common:label", "Label")}
                  fullWidth
                  required
                  value={localMaintenanceTask.label || ""}
                  error={!!getFieldError("label")}
                  helperText={getFieldError("label")}
                  onChange={(event) => {
                    onChange({ label: event.target.value });
                  }}
                />
                <FormControlLabel
                  label={"Required"}
                  control={
                    <Checkbox
                      checked={localMaintenanceTask.required || false}
                      onChange={(event, checked) => {
                        onChange({ required: checked });
                      }}
                    />
                  }
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DateTimePicker
                label="Start date"
                ampm={false}
                value={localMaintenanceTask?.startDate || null}
                onChange={(date) => {
                  onChange({ startDate: date });
                }}
                maxDate={localMaintenanceTask?.endDate || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    error={!!getFieldError("startDate")}
                    helperText={getFieldError("startDate")}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DateTimePicker
                label="End date"
                ampm={false}
                value={localMaintenanceTask?.endDate || null}
                onChange={(date) => {
                  onChange({ endDate: date });
                }}
                minDate={localMaintenanceTask?.startDate || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!getFieldError("endDate")}
                    helperText={getFieldError("endDate")}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <EnumSelect
                  enumObject={omitBy(
                    MaintenanceTaskRecurrenceFrequency,
                    (v) => v === MaintenanceTaskRecurrenceFrequency.Daily
                  )}
                  label={"Recurrence"}
                  fullWidth
                  withNoneOption
                  value={localMaintenanceTask.recurrence?.frequency || null}
                  error={!!getFieldError("recurrence")}
                  helperText={getFieldError("recurrence")}
                  onChange={(event, value) => {
                    if (!value) {
                      onChange({ recurrence: null });
                      return;
                    }
                    onChange({
                      recurrence: {
                        frequency: value,
                        interval:
                          localMaintenanceTask.recurrence?.interval || 1,
                      },
                    });
                  }}
                />
                {localMaintenanceTask.recurrence ? (
                  <NumberTextField
                    label="Interval"
                    placeholder="1"
                    value={localMaintenanceTask.recurrence?.interval || ""}
                    onChange={(event) => {
                      onChange({
                        recurrence: {
                          interval: parseInt(event.target.value),
                        },
                      });
                    }}
                    error={!!getFieldError("recurrence")}
                    helperText={getFieldError("recurrence")}
                  />
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          pt: 3,
        }}
      >
        <Button
          variant="contained"
          disabled={saving || !!validationResult?.error}
          size="large"
          onClick={() => {
            if (!localMaintenanceTask) {
              return;
            }
            if (validate()) {
              cancelConfirm();
              onSave(localMaintenanceTask as NewMaintenanceTaskInput);
            }
          }}
          id="saveMaintenanceTaskButton"
        >
          {t("common:save", "Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default MaintenanceTaskForm;
