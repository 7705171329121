import { ConditionValueSelectorComponentProps } from "../../ConditionForm";
import GoodsInput from "./GoodsInput";

type GoodsInputContainerProps = ConditionValueSelectorComponentProps;

const GoodsInputContainer = ({ ...props }: GoodsInputContainerProps) => {
  if (!props.customer) {
    return null;
  }
  return (
    <GoodsInput {...props} goods={props.customer.goodInventoryItems || []} />
  );
};

export default GoodsInputContainer;
