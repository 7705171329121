import {
  MaintenanceTaskStatus,
  Scalars,
  TripAssetTypes,
  useGetMaintenanceTasksQuery,
} from "../../../graphql/generated";
import MaintenanceWarnings, {
  MaintenanceWarningsProps,
} from "./MaintenanceWarnings";

type MaintenanceWarningsContainerProps = Omit<
  MaintenanceWarningsProps,
  "upcomingMaintenanceTasks" | "currentMaintenanceTasks"
> & {
  assetType: TripAssetTypes;
  assetId: Scalars["ObjectId"];
};

const MaintenanceWarningsContainer = ({
  assetType,
  assetId,
  ...props
}: MaintenanceWarningsContainerProps) => {
  const { data } = useGetMaintenanceTasksQuery({
    assetType,
    assetId,
  });

  const upcomingMaintenanceTasks = data?.maintenanceTasks.data.filter(
    (task) => new Date(task.startDate) > new Date()
  );

  const currentMaintenanceTasks = data?.maintenanceTasks.data.filter(
    (task) =>
      new Date(task.startDate) <= new Date() &&
      task.status !== MaintenanceTaskStatus.Completed
  );

  return (
    <MaintenanceWarnings
      upcomingMaintenanceTasks={upcomingMaintenanceTasks || []}
      currentMaintenanceTasks={currentMaintenanceTasks || []}
      {...props}
    />
  );
};

export default MaintenanceWarningsContainer;
