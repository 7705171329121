import { Box, Button, Chip, Stack, Tab, Tabs } from "@mui/material";
import {
  TripSortCriteria,
  Status,
  BusinessEntityType,
  GetBusinessEntityListQuery,
  ExtensionOperationContext,
  NewBusinessEntityInputWithType,
  CustomFieldContext,
} from "../../../graphql/generated";
import enumLabel from "../../../utils/labels/enumLabel";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import ExcelImporter from "../../common/ExcelImporter";
import locationsMapping from "../../../utils/mapping/locations";
import businessEntitySchema from "../BusinessEntityForm/businessEntitySchema";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

type CarriersListItem =
  GetBusinessEntityListQuery["businessEntities"]["data"][0];

enum CarriersListTab {
  CARRIERS = "CARRIERS",
  PAY_TO_PROFILES = "PAY_TO_PROFILES",
}

export default function BusinessLocationsList({
  query,
  onBulkCreate,
}: {
  query: QueryFn<CarriersListItem, "businessEntities", TripSortCriteria>;
  onBulkCreate: (locations: NewBusinessEntityInputWithType[]) => Promise<void>;
}) {
  const { t } = useTranslation(["business", "common"]);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location.pathname === "/payto"
      ? CarriersListTab.PAY_TO_PROFILES
      : CarriersListTab.CARRIERS
  );

  const fields: TableField<CarriersListItem, Status>[] = useMemo(
    () => [
      {
        value: "code",
        label: t("business:code", "Code"),
        type: "string",
      },
      {
        value: "name",
        label: t("business:name", "Name"),
        type: "string",
      },
      {
        value: (businessLocation) =>
          [businessLocation.type, ...(businessLocation.additionalTypes || [])]
            .map(enumLabel)
            .join(", "),
        label: t("business:type", "Type"),
        type: "string",
      },
      {
        value: (businessLocation) => businessLocation.address?.label,
        label: t("business:address", "Address"),
        type: "string",
      },
      {
        value: (businessLocation) =>
          formatPhoneNumber(businessLocation.contact.phoneNumber),
        subtitle: (businessLocation) => businessLocation.contact.email,
        label: t("business:contact", "Contact"),
        type: "string",
      },
      {
        value: (location) => (
          <Stack direction="row" spacing={1}>
            {location.tags?.map((tag) => (
              <Chip label={tag} />
            ))}
          </Stack>
        ),
        sortBy: "tags",
        label: t("common:tags"),
        type: "string",
      },
    ],
    [t]
  );

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(event, value) => {
            setActiveTab(value);
          }}
          aria-label="carriers filter tab"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab
            label={t("business:carrier.many", "Carriers")}
            value={CarriersListTab.CARRIERS}
          />
          <Tab
            label={t("business:payToProfile.many", "Pay To Profiles")}
            value={CarriersListTab.PAY_TO_PROFILES}
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignSelf: "flex-end",
          mb: 2,
        }}
      >
        <ExcelImporter
          mapping={locationsMapping}
          schema={businessEntitySchema}
          onBulkCreate={onBulkCreate}
        />

        <Button
          href={
            activeTab === CarriersListTab.CARRIERS
              ? "/carriers/new"
              : "/payto/new"
          }
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            alignSelf: "end",
            mb: 2,
          }}
          id={
            activeTab === CarriersListTab.CARRIERS
              ? "new-carrier-button"
              : "new-payto-button"
          }
        >
          {activeTab === CarriersListTab.CARRIERS
            ? t("business:carrier.new", "New Carrier")
            : t("business:payToProfile.new", "New Pay To Profile")}
        </Button>
      </Box>

      <Box
        sx={{
          height: "calc(100% - 112px)",
        }}
      >
        {activeTab === CarriersListTab.CARRIERS ? (
          <SmartLynksTable
            query={query}
            dataKey="businessEntities"
            additionalQueryVariables={{
              businessEntityTypes: [BusinessEntityType.Carrier],
            }}
            fields={fields}
            detailsUrlPrefix="/carriers/details"
            extensionOperationContext={
              ExtensionOperationContext.BusinessLocation
            }
            customFieldContext={CustomFieldContext.Carrier}
          />
        ) : null}
        {activeTab === CarriersListTab.PAY_TO_PROFILES ? (
          <SmartLynksTable
            query={query}
            dataKey="businessEntities"
            additionalQueryVariables={{
              businessEntityTypes: [BusinessEntityType.PayToProfile],
            }}
            fields={fields}
            detailsUrlPrefix="/payto/details"
            extensionOperationContext={
              ExtensionOperationContext.BusinessLocation
            }
          />
        ) : null}
      </Box>
    </Box>
  );
}
