import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import {
  DeductionType,
  GetDriverSettlementDetailsQuery,
  TransactionInput,
} from "../../../graphql/generated";
import TransactionsList from "../TransactionsList/TransactionsList";
import TripLink from "../../common/TripLink";

export type DriverSettlementDetailsProps = {
  driverSettlement: NonNullable<
    GetDriverSettlementDetailsQuery["driverSettlementById"]
  >;
  onDeductionAdded: (
    deductionType: DeductionType,
    transaction: TransactionInput
  ) => void;
  onDeductionChanged: (id: string, transaction: TransactionInput) => void;
  onDeductionDeleted: (id: string) => void;
};

const DriverSettlementDetails = ({
  driverSettlement,
  onDeductionAdded,
  onDeductionChanged,
  onDeductionDeleted,
}: DriverSettlementDetailsProps) => {
  return (
    <Box>
      <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
        {driverSettlement.pdfDocument.url ? (
          <Button
            target="_blank"
            href={driverSettlement.pdfDocument.url}
            variant="contained"
            LinkComponent="a"
          >
            Open Settlement Document
          </Button>
        ) : null}
      </Stack>
      <Card
        sx={{
          mb: 2,
        }}
      >
        <CardHeader title="Trips" />
        <CardContent>
          <Typography>
            {driverSettlement.trips?.map((trip) => (
              <TripLink trip={trip} />
            ))}
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h4" gutterBottom>
        Payments
      </Typography>
      <TransactionsList
        transactionLabel="Payment"
        query={() => ({
          data: {
            transactions: {
              data: driverSettlement.payments.map((payment) => {
                const trip = driverSettlement.trips?.find(
                  (trip) => trip._id === payment.trip
                );
                return {
                  ...payment,
                  number: trip ? `Trip ${trip?.tripNumber}` : "N/A",
                };
              }),
              count: driverSettlement.payments.length,
            },
          },
          isLoading: false,
        })}
        onTransactionAdded={() => {}}
        onTransactionChanged={() => {}}
        deleteMutation={() => ({
          mutateAsync: async () => {},
          isLoading: false,
        })}
        editable={false}
      />

      <Typography variant="h4" gutterBottom>
        Bonuses
      </Typography>
      <TransactionsList
        transactionLabel="Bonuses"
        isFlatFee
        query={() => ({
          data: {
            transactions: {
              data: driverSettlement.deductions
                .filter((deduction) => deduction.type === DeductionType.Bonus)
                .map((deduction) => ({
                  _id: deduction._id,
                  label: deduction.label,
                  unitType: "Flat Fee",
                  unit: 1,
                  rate: deduction.amount,
                })),
              count: driverSettlement.deductions.filter(
                (deduction) => deduction.type === DeductionType.Bonus
              ).length,
            },
          },
          isLoading: false,
        })}
        onTransactionAdded={onDeductionAdded.bind(null, DeductionType.Bonus)}
        onTransactionChanged={onDeductionChanged}
        deleteMutation={() => ({
          mutateAsync: async ({ id }) => {
            onDeductionDeleted(id);
          },
          isLoading: false,
        })}
      />

      <Typography variant="h4" gutterBottom>
        Deductions
      </Typography>
      <TransactionsList
        transactionLabel="Deductions"
        isFlatFee
        query={() => ({
          data: {
            transactions: {
              data: driverSettlement.deductions
                .filter(
                  (deduction) => deduction.type === DeductionType.Deduction
                )
                .map((deduction) => ({
                  _id: deduction._id,
                  label: deduction.label,
                  unitType: "Flat Fee",
                  unit: 1,
                  rate: -deduction.amount,
                })),
              count: driverSettlement.deductions.filter(
                (deduction) => deduction.type === DeductionType.Deduction
              ).length,
            },
          },
          isLoading: false,
        })}
        onTransactionAdded={(transaction) =>
          onDeductionAdded(DeductionType.Deduction, {
            ...transaction,
            rate: -transaction.rate,
          })
        }
        onTransactionChanged={(transactionId, transaction) => {
          onDeductionChanged(transactionId, {
            ...transaction,
            rate: -transaction.rate,
          });
        }}
        deleteMutation={() => ({
          mutateAsync: async ({ id }) => {
            onDeductionDeleted(id);
          },
          isLoading: false,
        })}
      />
    </Box>
  );
};

export default DriverSettlementDetails;
