import { Box, Button, Chip, Stack } from "@mui/material";
import {
  TripSortCriteria,
  Status,
  BusinessEntityType,
  GetBusinessEntityListQuery,
  ExtensionOperationContext,
  NewBusinessEntityInputWithType,
  CustomFieldContext,
} from "../../../graphql/generated";
import enumLabel from "../../../utils/labels/enumLabel";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import ExcelImporter from "../../common/ExcelImporter";
import locationsMapping from "../../../utils/mapping/locations";
import businessEntitySchema from "../BusinessEntityForm/businessEntitySchema";
import RoleGuard from "../../account/Access/RoleGuard";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

type BusinessLocationListItem =
  GetBusinessEntityListQuery["businessEntities"]["data"][0];

export default function BusinessLocationsList({
  query,
  onBulkCreate,
}: {
  query: QueryFn<
    BusinessLocationListItem,
    "businessEntities",
    TripSortCriteria
  >;
  onBulkCreate: (locations: NewBusinessEntityInputWithType[]) => Promise<any>;
}) {
  const { t } = useTranslation(["business", "common"]);

  const fields: TableField<BusinessLocationListItem, Status>[] = useMemo(
    () => [
      {
        value: "code",
        label: t("business:code", "Code"),
        type: "string",
      },
      {
        value: "name",
        label: t("business:name", "Name"),
        type: "string",
      },
      {
        value: (businessLocation) =>
          [businessLocation.type, ...(businessLocation.additionalTypes || [])]
            .map(enumLabel)
            .join(", "),
        label: t("business:type", "Type"),
        type: "string",
      },
      {
        value: (businessLocation) => businessLocation.address?.label,
        label: t("business:address", "Address"),
        type: "string",
      },
      {
        value: (businessLocation) =>
          formatPhoneNumber(businessLocation.contact.phoneNumber),
        subtitle: (businessLocation) => businessLocation.contact.email,
        label: t("business:contact", "Contact"),
        type: "string",
      },
      {
        value: (location) => (
          <Stack direction="row" spacing={1}>
            {location.tags?.map((tag) => (
              <Chip label={tag} />
            ))}
          </Stack>
        ),
        sortBy: "tags",
        label: t("common:tags"),
        type: "string",
      },
    ],
    [t]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <RoleGuard roles={["Carrier Admin", "Manager", "Clerical"]}>
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            mb: 2,
          }}
        >
          <ExcelImporter
            mapping={locationsMapping}
            schema={businessEntitySchema}
            onBulkCreate={onBulkCreate}
          />

          <Button
            href="/business-locations/new"
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              alignSelf: "end",
              mb: 2,
            }}
            id="new-businessLocation-button"
          >
            {t("business:location.new", "New Location")}
          </Button>
        </Box>
      </RoleGuard>
      <Box
        sx={{
          height: "calc(100% - 74px)",
        }}
      >
        <SmartLynksTable
          query={query}
          dataKey="businessEntities"
          additionalQueryVariables={{
            businessEntityTypes: [
              BusinessEntityType.Shipper,
              BusinessEntityType.Receiver,
              BusinessEntityType.GeneralLocation,
              BusinessEntityType.Port,
              BusinessEntityType.TruckStop,
              BusinessEntityType.Terminal,
              BusinessEntityType.Domicile,
            ],
          }}
          fields={fields}
          detailsUrlPrefix="/business-locations/details"
          extensionOperationContext={ExtensionOperationContext.BusinessLocation}
          customFieldContext={CustomFieldContext.Location}
          id="business-locations-list"
        />
      </Box>
    </div>
  );
}
