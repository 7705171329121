import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import UserForm from "../../components/account/UserForm";

function EditUser() {
  const { id } = useParams();
  if (!id) {
    return null;
  }
  return (
    <Container>
      <UserForm userId={id} />
    </Container>
  );
}

export default EditUser;
