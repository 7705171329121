import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import BillingRulesEditor from "../../../components/accounting/BillingRulesEditor";
import BusinessEntityDetailsContainer from "../../../components/asset-management/BusinessEntityDetails";
import HeaderTitle from "../../../components/common/HeaderTitle";
import { BusinessEntity, BusinessEntityType } from "../../../graphql/generated";
import { useNavigationOptions } from "../../LayoutRoutes";
import { useTranslation } from "react-i18next";

function ViewCustomer() {
  const { id } = useParams();
  const { setNavigationOptions } = useNavigationOptions();
  const { t } = useTranslation("common");
  const onLoad = useCallback(
    (businessEntity: Pick<BusinessEntity, "code">) => {
      setNavigationOptions({
        title: (
          <HeaderTitle label="Customer" suffix={businessEntity?.code || ""} />
        ),
        showBackIcon: true,
      });
    },
    [setNavigationOptions]
  );
  const [activeTab, setActiveTab] = useState("DETAILS");
  if (!id) {
    return null;
  }
  return (
    <TabContext value={activeTab}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={(event, value) => setActiveTab(value)}
          aria-label="Customer tabs"
        >
          <Tab label={t("details")} value="DETAILS" />
          <Tab label={t("billing")} value="BILLING" />
        </TabList>
      </Box>
      <TabPanel value="DETAILS">
        <BusinessEntityDetailsContainer
          businessEntityId={id}
          onLoad={onLoad}
          urlPrefix="/customers"
        />
      </TabPanel>
      <TabPanel value="BILLING">
        <BillingRulesEditor
          customerId={id}
          customerType={BusinessEntityType.Customer}
        />
      </TabPanel>
    </TabContext>
  );
}

export default ViewCustomer;
