import { ReactNode, useEffect } from "react";
import { useGetOrganizationSettingsQuery } from "../../../graphql/generated";
import StaticLocaleProvider from "../../../providers/LocaleProvider";

const LocaleProvider = (props: { children: ReactNode }) => {
  const organizationSettingsQuery = useGetOrganizationSettingsQuery();

  const organizationSettings =
    organizationSettingsQuery.data?.organizationSettings;

  useEffect(() => {
    if (organizationSettings?.general?.measurementSystem) {
      StaticLocaleProvider.setMeasurementSystem(
        organizationSettings.general.measurementSystem
      );
    }
    if (organizationSettings?.general?.language) {
      StaticLocaleProvider.setLanguage(organizationSettings.general.language);
    }
  }, [organizationSettings]);

  return <>{props.children}</>;
};

export default LocaleProvider;
