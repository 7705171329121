import { Box, Typography } from "@mui/material";
import { capitalize } from "lodash";
import React, { useEffect } from "react";

type HeaderTitleProps = {
  label?: string;
  suffix?: string;
};

const HeaderTitle = ({ label, suffix }: HeaderTitleProps) => {
  useEffect(() => {
    document.title = capitalize(`${label} ${suffix}`);
  }, [label, suffix]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textTransform: "capitalize",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          ml: 2,
          fontWeight: "600",
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          ml: 1,
          fontWeight: "400",
          color: "black",
        }}
      >
        {suffix}
      </Typography>
    </Box>
  );
};

export default HeaderTitle;
