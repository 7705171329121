import { useQueryClient } from "@tanstack/react-query";
import { toArray } from "lodash";
import {
  ShipmentLocationType,
  useGetReceiversQuery,
  useGetShippersQuery,
  useGetShipperDetailsQuery,
  useGetReceiverDetailsQuery,
  useGetRelationshipListQuery,
} from "../../../../graphql/generated";
import ShipmentLocationForm, {
  ShipmentLocationFormProps,
} from "./ShipmentLocationForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

type ShipmentLocationFormContainerProps = Omit<
  ShipmentLocationFormProps,
  "businessEntities" | "onBusinessEntitiesRefetch" | "fetchBusinessEntity"
>;

const ShipmentLocationFormContainer = (
  props: ShipmentLocationFormContainerProps
) => {
  const shippersQuery = useGetShippersQuery();
  const receiversQuery = useGetReceiversQuery();
  const queryClient = useQueryClient();

  const shippers = shippersQuery.data?.shippers.data || [];
  const receivers = receiversQuery.data?.receivers.data || [];

  const relationsQuery = useGetRelationshipListQuery();

  const relations = relationsQuery.data?.relationships.data || [];

  const stateShipment = useSelector(
    (state: RootState) => state.shipmentReducer.shipment
  );

  const currentReceiversWithCommodities = stateShipment.shipmentLocations
    .filter(
      (location) => location.locationType === ShipmentLocationType.DropOff
    )
    .flatMap((location) =>
      location.receivedGoods.map((good) => ({
        goodProfileId: good.goodProfileId || good.goodId,
        receiverId: location.receiver,
      }))
    );

  const eligibleRelations = relations.filter(
    (relation) =>
      relation.commodities?.some((commodity) =>
        currentReceiversWithCommodities.some(
          (receiverWithCommodity) =>
            receiverWithCommodity.goodProfileId === commodity._id
        )
      ) &&
      relation.receivers?.some((receiver) =>
        currentReceiversWithCommodities.some(
          (receiverWithCommodity) =>
            receiverWithCommodity.receiverId === receiver._id
        )
      )
  );

  const eligibleShipperIds = eligibleRelations.flatMap((relation) =>
    (relation.shippers || []).map((shipper) => shipper._id)
  );

  return (
    <ShipmentLocationForm
      {...props}
      businessEntities={
        props.locationType === ShipmentLocationType.Pickup
          ? shippers
          : receivers
      }
      filterBusinessEntityIds={
        props.locationType === ShipmentLocationType.Pickup &&
        eligibleShipperIds.length
          ? eligibleShipperIds
          : undefined
      }
      fetchBusinessEntity={async (businessEntityId) => {
        const result = await (props.locationType === ShipmentLocationType.Pickup
          ? queryClient.fetchQuery(
              useGetShipperDetailsQuery.getKey({
                id: businessEntityId,
              }),
              useGetShipperDetailsQuery.fetcher({
                id: businessEntityId,
              })
            )
          : queryClient.fetchQuery(
              useGetReceiverDetailsQuery.getKey({
                id: businessEntityId,
              }),
              useGetReceiverDetailsQuery.fetcher({
                id: businessEntityId,
              })
            ));

        return toArray(result)[0];
      }}
    />
  );
};

export default ShipmentLocationFormContainer;
