import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { format, isValid, parse } from "date-fns";
import { CustomFieldInputComponent } from "../CustomFieldsForm";

const TimeInput: CustomFieldInputComponent = ({
  value,
  customFieldDefinition,
  onChange,
}) => {
  const parsedDate = parse(String(value), "HH:mm", new Date());
  return (
    <TimePicker
      value={value && isValid(parsedDate) ? parsedDate : null}
      label={customFieldDefinition.label}
      ampm={false}
      onChange={(value) => {
        if (!value) {
          return;
        }
        if (!isValid(value)) {
          return;
        }
        onChange(format(value, "HH:mm"));
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default TimeInput;
