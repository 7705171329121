import {
  Button,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  BillingMethod,
  BillingRuleConditionOperator,
  BillingRuleConditionTarget,
  GetCustomerDetailsQuery,
} from "../../../graphql/generated";
import LocaleProvider from "../../../providers/LocaleProvider";
import billingMethodLabel from "../../../utils/labels/billingRule/billingMethodLabel";
import ConditionForm from "./ConditionForm";
import { BillingRuleItem } from "../BillingRulesEditor/BillingRulesEditor";
import { Clear } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type BillingRuleFormProps = {
  billingRule: BillingRuleItem;
  onChange: (billingRule: BillingRuleItem) => void;
  customer: GetCustomerDetailsQuery["customerById"];
};

const BillingRuleForm = ({
  billingRule,
  customer,
  onChange,
}: BillingRuleFormProps) => {
  const { t } = useTranslation("billingRules");
  return (
    <Stack direction="row" alignItems="flex-start" spacing={2}>
      <Typography
        variant="body2"
        sx={{
          pt: 1.4,
        }}
        color="primary"
        fontWeight="bold"
      >
        {t("form.when")}
      </Typography>
      <Stack spacing={1}>
        {billingRule.conditions?.map((condition, index) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption">
              {index === 0 ? "" : t("form.and")}
            </Typography>
            <ConditionForm
              customer={customer}
              key={`condition-${index}-${condition.target}-${condition.operator}-${condition.value}`}
              condition={condition}
              onChange={(condition) =>
                onChange({
                  ...billingRule,
                  conditions: billingRule.conditions?.map((c, i) =>
                    i === index ? condition : c
                  ),
                })
              }
            />
            {billingRule.conditions.length > 1 ? (
              <IconButton size="small">
                <Clear
                  fontSize="small"
                  onClick={() => {
                    onChange({
                      ...billingRule,
                      conditions: billingRule.conditions?.filter(
                        (c, i) => i !== index
                      ),
                    });
                  }}
                />
              </IconButton>
            ) : null}
          </Stack>
        ))}
        <Button
          onClick={() => {
            onChange({
              ...billingRule,
              conditions: [
                ...(billingRule.conditions || []),
                {
                  target: BillingRuleConditionTarget.TrailerType,
                  operator: BillingRuleConditionOperator.Equals,
                  value: null,
                },
              ],
            });
          }}
          sx={{
            alignSelf: "flex-start",
          }}
        >
          {t("form.+Condition")}
        </Button>
      </Stack>
      <Typography
        variant="body2"
        sx={{
          pt: 1.4,
        }}
        color="primary"
        fontWeight="bold"
      >
        {t("form.thenBill")}
      </Typography>
      <Select
        label="Billing method"
        size="small"
        value={billingRule.method}
        onChange={(event) => {
          onChange({
            ...billingRule,
            method: event.target.value as BillingMethod,
          });
        }}
      >
        {Object.values(BillingMethod).map((billingMethod) => (
          <MenuItem key={billingMethod} value={billingMethod}>
            <ListItemText primary={billingMethodLabel(billingMethod)} />
          </MenuItem>
        ))}
      </Select>
      <TextField
        type="number"
        value={String(billingRule.amount) || ""}
        onChange={(event) => {
          onChange({
            ...billingRule,
            amount: parseFloat(event.target.value) || 0,
          });
        }}
        size="small"
        label={t("form.amount")}
        inputProps={{
          type: "number",
          min: 0,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {LocaleProvider.getCurrencySymbol()}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        type="string"
        value={billingRule.referenceNumber || ""}
        onChange={(event) => {
          onChange({
            ...billingRule,
            referenceNumber: event.target.value,
          });
        }}
        size="small"
        label={t("form.reference")}
      />
    </Stack>
  );
};

export default BillingRuleForm;
