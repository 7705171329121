import { Link } from "@mui/material";
import { capitalize } from "lodash";
import {
  NoteAccessLevel,
  ShipmentDocument,
} from "../../../../graphql/generated";
import { ShipmentDocumentInputData } from "../../../../redux/slices/Types";
import LynksTable, { TableField } from "../../../common/LynksTable/LynksTable";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export type DocumentType = Pick<
  ShipmentDocument,
  "_id" | "url" | "accessLevel" | "name" | "isBillable"
> & {
  receiver: { _id: string; name: string };
} & {
  shipper: { _id: string; name: string };
};

export type DocumentsListProps = {
  onDelete?: (id: string) => void;
  documents: Array<DocumentType>;
  onChange: (documents: ShipmentDocumentInputData[]) => void;
  readonly?: boolean;
};

export default function DocumentsList({
  onDelete,
  documents,
  onChange,
  readonly,
}: DocumentsListProps) {
  const { t } = useTranslation("orders");
  const noteTableFields: Array<TableField<DocumentType>> = useMemo(
    () => [
      {
        label: t("documents.document"),
        type: "custom",
        value: (document: DocumentType) => {
          return (
            <Link target="_blank" component="a" href={document.url}>
              {document.name}
            </Link>
          );
        },
      },
      {
        label: t("documents.visibility"),
        type: "string",
        value: (document: DocumentType) => {
          if (
            document.accessLevel.length === Object.keys(NoteAccessLevel).length
          ) {
            return "All";
          } else {
            return capitalize(document.accessLevel.join(", "));
          }
        },
      },
      {
        label: t("documents.location"),
        type: "string",
        value: (document: DocumentType) => {
          return document.shipper?.name || document.receiver?.name;
        },
      },
      {
        label: t("documents.includeInInvoice"),
        type: "boolean",
        value: "isBillable",
      },
    ],
    [t]
  );
  return (
    <LynksTable
      fields={noteTableFields}
      data={documents}
      showEmptyStateImage={false}
      onDelete={!readonly ? onDelete : undefined}
      onRowChange={
        !readonly
          ? (id, updatedDocument) => {
              onChange(
                documents
                  .map((document) =>
                    document._id === id ? updatedDocument : document
                  )
                  .map((doc) => ({
                    ...doc,
                    shipper: doc.shipper._id || null,
                    receiver: doc.receiver._id || null,
                    type: "",
                  }))
              );
            }
          : undefined
      }
    />
  );
}
