import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EditShipmentNoteInput,
  GetShipmentQuery,
  InvoicingProcess,
  ShipmentNoteInput,
  TransactionInput,
  useAddShipmentDocumentsMutation,
  useAddShipmentNotesMutation,
  useDeleteDocumentShipmentMutation,
  useDeleteShipmentMutation,
  useDeleteShipmentNoteMutation,
  useEditShipmentNoteMutation,
  useGetOrganizationSettingsQuery,
  useGetShipmentQuery,
  useGetTripQuery,
  useRestoreShipmentMutation,
  useUpdateShipmentMutation,
} from "../../../graphql/generated";
import { showToast } from "../../../redux/slices/alert/Alert.slice";
import {
  ShipmentChargeInputData,
  ShipmentDocumentInputData,
} from "../../../redux/slices/Types";
import ShipmentInfos, { ShipmentInfosProps } from "./ShipmentInfos";
import { omit } from "lodash";
import { useSnackbar } from "notistack";
import useDialog from "../../../utils/hooks/useDialog";
import { useTranslation } from "react-i18next";

export type ShipmentInfo = GetShipmentQuery["shipmentById"];

type ShipmentInfosContainerProps = Omit<
  ShipmentInfosProps,
  | "onDelete"
  | "onRestore"
  | "loading"
  | "uploadingDocuments"
  | "error"
  | "shipment"
  | "trip"
  | "onDocumentsUploaded"
  | "onNotesAdded"
  | "onNoteEdited"
  | "onNoteDeleted"
  | "onDocumentDeleted"
  | "onDocumentsChanged"
  | "onChargeAdded"
  | "onChargesChanged"
  | "onChargeDeleted"
  | "onExpenseAdded"
  | "onExpensesChanged"
  | "onExpenseDeleted"
  | "onRefresh"
  | "canInvoice"
> & {
  onLoad?: (shipment: ShipmentInfo) => void;
  onDeleted?: (shipmentId: string) => void;
  shipmentId: string;
};

const ShipmentInfosContainer = ({
  onLoad,
  onDeleted,
  shipmentId,
  ...props
}: ShipmentInfosContainerProps) => {
  const { t } = useTranslation(["orders", "common"]);
  const { data, isLoading, error, refetch } = useGetShipmentQuery({
    id: shipmentId,
  });
  const getTripDetails = useGetTripQuery({
    id: data?.shipmentById?.tripId || "",
  });
  const updateShipmentMutation = useUpdateShipmentMutation();
  const deleteShipmentMutation = useDeleteShipmentMutation();
  const restoreShipmentMutation = useRestoreShipmentMutation();
  const [shipment, setShipment] = useState<ShipmentInfo>();
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog, hideDialog } = useDialog();
  const { mutateAsync: addShipmentDocuments } = useAddShipmentDocumentsMutation(
    {}
  );
  const { mutateAsync: addShipmentNotes } = useAddShipmentNotesMutation({});
  const { mutateAsync: editNoteShipment } = useEditShipmentNoteMutation({});
  const { mutateAsync: deleteShipmentNote } = useDeleteShipmentNoteMutation({});
  const { mutateAsync: deleteDocumentShipment } =
    useDeleteDocumentShipmentMutation({});

  const getOrgSettingsQuery = useGetOrganizationSettingsQuery();

  useEffect(() => {
    if (data?.shipmentById) {
      setShipment(data?.shipmentById);
    }
  }, [data]);

  useEffect(() => {
    if (data?.shipmentById) {
      onLoad?.(data?.shipmentById);
    }
  }, [data?.shipmentById, onLoad]);

  const handleDocumentsUploaded = async (
    documents: Array<ShipmentDocumentInputData>
  ) => {
    if (!documents.length) {
      return null;
    }
    setIsUploading(true);
    try {
      await Promise.all(
        documents.map(async (d) => {
          await fetch(d.url, {
            method: "PUT",
            body: d.file,
          });
        })
      );
      await addShipmentDocuments({
        data: documents.map((d) => omit(d, ["file", "_id"])),
        shipment: shipment?._id || "",
      });
      enqueueSnackbar(
        t(
          "common:documents.filesUploadSuccess",
          "File(s) successfully uploaded"
        )
      );
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "common:documents.errors.uploadDocumentsError",
          "Error while uploading document(s)"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleNotesAdded = async (notes: Array<ShipmentNoteInput>) => {
    try {
      await addShipmentNotes({
        data: {
          notes: notes.map((note) => omit(note, "_id")),
          shipment: shipment?._id || "",
        },
      });
      enqueueSnackbar(t("notes.addNotesSuccess", "Note(s) successfully added"));
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t("orders:error.addNotesError", "Error while adding note(s)"),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  const handleNoteEdited = async (note: EditShipmentNoteInput) => {
    try {
      await editNoteShipment({
        data: { ...note, shipment: shipment?._id },
      });
      enqueueSnackbar(t("notes.updateSuccess", "Note updated"));
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "error.updateNoteError",
          "An error occurred while updating note"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  const handleNoteDeleted = async (id: string) => {
    try {
      await deleteShipmentNote({
        data: { _id: id, shipment: shipment?._id || "" },
      });
      enqueueSnackbar(
        t("notes.deleteNoteSuccess", "Note successfully deleted")
      );
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "error.deleteNoteError",
          "An error occurred while deleting note"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  const handleDocumentDeleted = async (id: string) => {
    try {
      await deleteDocumentShipment({
        data: { _id: id, shipment: shipment?._id || "" },
      });
      enqueueSnackbar("Document successfully deleted");
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "common:documents.errors.deleteError",
          "An error occurred while deleting document"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  const handleDocumentsChanged = async (
    documents: ShipmentDocumentInputData[]
  ) => {
    try {
      if (!shipment) {
        return;
      }
      await updateShipmentMutation.mutateAsync({
        id: shipment._id,
        editShipmentData: {
          documents: documents.map((document) => omit(document, "_id", "file")),
        },
      });
      enqueueSnackbar(
        t("common:documents.updateSuccess", "Document successfully edited")
      );
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "common:documents.errors.updateDocumentError",
          "An error occurred while editing the document"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  const handleChargeAdded = async (charge: ShipmentChargeInputData) => {
    try {
      if (!shipment) {
        return;
      }
      await updateShipmentMutation.mutateAsync({
        id: shipment._id,
        editShipmentData: {
          charges: shipment.charges
            .map((charge) => ({
              ...omit(charge, "total"),
              billingRule: charge.billingRule?._id,
            }))
            .concat({
              ...charge,
              _id: charge._id || "",
              billingRule: charge.billingRule || undefined,
            }),
        },
      });
      enqueueSnackbar(t("addChargeSuccess", "Charge successfully added"));
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "error.addChargeError",
          "An error occurred while adding the charge"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  const handleChargeDeleted = async (id: string) => {
    try {
      if (!shipment) {
        return;
      }
      await updateShipmentMutation.mutateAsync({
        id: shipment._id,
        editShipmentData: {
          charges: shipment.charges
            .filter((charge) => charge._id !== id)
            .map((charge) => ({
              ...omit(charge, "total", "_id"),
              billingRule: charge.billingRule?._id,
            })),
        },
      });
      enqueueSnackbar(t("deleteChargeSuccess", "Charge successfully deleted"));
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "error.deleteChargeError",
          "An error occurred while deleting charge"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  const handleChargesChanged = async (charges: ShipmentChargeInputData[]) => {
    try {
      if (!shipment) {
        return;
      }
      await updateShipmentMutation.mutateAsync({
        id: shipment._id,
        editShipmentData: {
          charges: charges.map((charge) => omit(charge, "total")),
        },
      });
      enqueueSnackbar(t("updateChargeSuccess", "Charge successfully edited"));
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "error.updateChargeError",
          "An error occurred while editing the charge"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  const handleExpenseAdded = async (expense: TransactionInput) => {
    try {
      if (!shipment) {
        return;
      }
      await updateShipmentMutation.mutateAsync({
        id: shipment._id,
        editShipmentData: {
          // @ts-ignore
          expenses: shipment.expenses?.concat(expense),
        },
      });
      enqueueSnackbar(t("addExpenseSuccess", "Expense successfully added"));
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "error.addExpenseError",
          "An error occurred while adding the expense"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  const handleExpenseDeleted = async (id: string) => {
    try {
      if (!shipment) {
        return;
      }
      await updateShipmentMutation.mutateAsync({
        id: shipment._id,
        editShipmentData: {
          expenses: shipment.expenses?.filter((expense) => expense._id !== id),
          charges: shipment.charges
            .filter((charge) => charge.relatedTransactionId !== id)
            .map((charge) => ({
              ...omit(charge, "total"),
              billingRule: charge.billingRule?._id,
            })),
        },
      });
      enqueueSnackbar(
        t("deleteExpenseSuccess", "Expense successfully deleted")
      );
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "error.deleteExpenseError",
          "An error occurred while deleting expense"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  const handleExpensesChanged = async (expenses: TransactionInput[]) => {
    try {
      if (!shipment) {
        return;
      }
      await updateShipmentMutation.mutateAsync({
        id: shipment._id,
        editShipmentData: {
          expenses,
        },
      });
      enqueueSnackbar(t("updateExpenseSuccess", "Expense successfully edited"));
      refetch();
    } catch (error) {
      showDialog({
        type: "error",
        title: t(
          "error.updateExpenseError",
          "An error occurred while editing the expense"
        ),
        description:
          (error as Error).message ||
          t("common:error.unknownError", "Unknown error"),
      });
    }
  };

  return (
    <ShipmentInfos
      shipment={shipment}
      trip={getTripDetails.data?.tripById}
      loading={isLoading}
      uploadingDocuments={isUploading}
      error={error}
      canInvoice={getOrgSettingsQuery.data?.organizationSettings?.invoicing?.processes?.includes(
        InvoicingProcess.LoadBased
      )}
      onDocumentsUploaded={handleDocumentsUploaded}
      onNotesAdded={handleNotesAdded}
      onNoteEdited={handleNoteEdited}
      onNoteDeleted={handleNoteDeleted}
      onDocumentDeleted={handleDocumentDeleted}
      onDocumentsChanged={handleDocumentsChanged}
      onChargeAdded={handleChargeAdded}
      onChargeDeleted={handleChargeDeleted}
      onChargesChanged={handleChargesChanged}
      onExpenseAdded={handleExpenseAdded}
      onExpenseDeleted={handleExpenseDeleted}
      onExpensesChanged={handleExpensesChanged}
      onDelete={async (reason) => {
        try {
          await deleteShipmentMutation.mutateAsync({
            id: shipmentId,
            deleteShipmentData: {
              reason,
            },
          });
          if (onDeleted) {
            onDeleted(shipmentId);
          } else {
            navigate("/orders");
          }
        } catch (error) {
          showToast({
            type: "error",
            message: `${t(
              "cancelShipmentError",
              "An error occurred when trying to cancel this shipment"
            )}: ${
              (deleteShipmentMutation.error as Error).message ||
              t("common:error.unknownError", "Unknown error")
            }`,
          });
        }
      }}
      onRestore={() => {
        showDialog({
          title: t("common:confirm", "Confirm"),
          description: t("restoreConfirmation", {
            shipmentNumber: shipment?.shipmentNumber,
            defaultValue: `Restore load ${shipment?.shipmentNumber} ?`,
          }),
          type: "primary",
          actions: [
            {
              title: t("common:cancel", "Cancel"),
              type: "lightPrimary",
            },
            {
              title: t("yesRestore", "Yes, restore"),
              type: "primary",
              onClick: async () => {
                try {
                  hideDialog();
                  await restoreShipmentMutation.mutateAsync({
                    id: shipmentId,
                  });
                  refetch();
                } catch (error) {
                  showToast({
                    type: "error",
                    message: `${t(
                      "error.restoreError",
                      "An error occurred when trying to restore this shipment"
                    )}: ${
                      (restoreShipmentMutation.error as Error).message ||
                      t("common:error.unknownError", "Unknown error")
                    }`,
                  });
                }
              },
            },
          ],
        });
      }}
      onRefresh={() => refetch()}
      {...props}
    />
  );
};

export default ShipmentInfosContainer;
