import Joi from "joi";
import {
  MaintenanceTask,
  MaintenanceTaskRecurrence,
} from "../../../graphql/generated";

const recurrenceSchema = Joi.object<MaintenanceTaskRecurrence>({
  frequency: Joi.string().required().label("Frequency"),
  interval: Joi.number().allow(null).label("Interval"),
});
const maintenanceTaskSchema = Joi.object<MaintenanceTask>({
  assetType: Joi.string().required().label("Asset Type"),
  assetId: Joi.string().required().label("Asset ID"),
  label: Joi.string().required().label("Label"),
  startDate: Joi.date().required().label("Start Date"),
  endDate: Joi.date().allow(null).label("End Date"),
  required: Joi.boolean().allow(null).label("Required"),
  recurrence: recurrenceSchema.allow(null).label("Recurrence"),
  status: Joi.string().allow(null).label("Status"),
});

export default maintenanceTaskSchema;
